import { combineReducers } from "redux";

import {
  LOGGED_OUT,
  LOGGED_IN,
  LOGIN,
  LOGOUT,
  FAILED_LOGIN,
  FAILED_SIGNUP,
  CONTACTS_LIST,
  CONTACTS,
  CLIENTS,
  SUJET,
  ADD_EVENT,
  EVENTS_LIST,
  EVENT_EDIT,
  MESSAGE_LOGIN,
  SHOW_MESSAGE,
  EVENTS_INITIAL,
  CONTACTS_EVENT,
  EVENT_FILTER,
  ACTION_EVENT
} from "../actions/types"

export const usersReducer = (
    state = { isLoggedIn: false, user: {},showLoading:false, usernamerror: "",eventFilter:{}, passwordError: "", params: {}, contacts: [],client:{}, token: "", extranet_url:"", user_events: [], message_login: "", usernameErr: ""},
    action
  ) => {
    const { payload, emailEr, passwordEr, emailError, passwordErr, usernameErr, passwordConfirmationError, status,  type } = action;
    
    switch (type) {
      case EVENT_FILTER:
        return{
          ...state,
          eventFilter: payload
        }
      case SHOW_MESSAGE:
        return {
          ...state,
          message_error: true,
        };
      case EVENTS_LIST:
        return {
          ...state,
          user_events: payload.user_events,
          showLoading:false,
        };
      case CONTACTS_LIST:
        return {
          ...state,
          contacts: payload.contacts,
        };
      
      case LOGIN:
        localStorage.setItem("token", JSON.stringify(payload.token))
        localStorage.setItem("user", JSON.stringify(payload.user))
        return {
          isLoggedIn: true,
          user: payload,
          eventFilter: null,
          message_login: false,
          usernameError: "",
          user_events:[]
        };
      case ACTION_EVENT.GET_EXTRANET_URL :
        return {
          ...state,
          extranet_url:payload.extranet_url
        }
      case MESSAGE_LOGIN:
        return {
          ...state,
          message_login: false,
          usernameError: "",
          message_error: false,
        };  
  
      case FAILED_LOGIN:
        return {
          message_login: true,
          usernameError: payload.username,
          message_error: payload.message_error,
        };
      
      
      case LOGGED_IN:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
          token: payload.token,
          params: payload.params,
          message_login: false,
          usernameError: "",
          client:{}
        };
  
      case LOGGED_OUT:
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        return {
          isLoggedIn: false,
          
          user: {},
        };
  
      case LOGOUT:
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        return {
          isLoggedIn: false,
          usersReducer: {},
        };

      case ACTION_EVENT.RELOAD_DATA :
        return {
          ...state,
          user: payload,
        };
      case ACTION_EVENT.LOADING_TOGGLE_ACTION:
        return {
          ...state,
          showLoading:payload
        }
      default:
        return state;
    }
  };

  export const eventGRC = (
    state = { clients: [], contacts: [], client: {},contactsEvent: [], sujet: {} , canal: "", trello: "", comment: "", duration: "",
     message:"", event_edit: {}},
    action
  ) => {
    const { payload, status,  type } = action;
    
    switch (type) {

      case CONTACTS_EVENT:
        return{
          ...state,
        contacts:payload.contacts,
        }
      case EVENTS_INITIAL:
        return{
          ...state,
        clients: [],
        contacts:[],
        sujet:[],
        }
      case ACTION_EVENT.CLIENT_SELECTED:
        return {
          ...state,
          client: payload,
        };
      case EVENT_EDIT:
        return {
          ...state,
          event_edit: payload.event_edit,
          contactsEvent:payload.contacts
        };
      case ADD_EVENT:
        return {
          ...state,
          messageAlert: payload.show_message,
        };
        
      case ACTION_EVENT.EDIT_EVENT:
        return {
          ...state,
          messageAlert: payload.show_message,
        };
      case ACTION_EVENT.DELETE_EVENT:
        return {
          ...state,
          messageAlert: payload.show_message,
        };
      case CONTACTS:
        return {
          ...state,
          contacts: payload.contacts,
        };
  
      case CLIENTS:
        return {
          ...state,
          clients: payload.clients,
        };       
      case SUJET:
        return {
          ...state,
          sujet: payload.sujet,
        };
  
      default:
        return state;
    }
  };
  export const rootReducer = combineReducers({
    usersReducer,
    eventGRC,
  });