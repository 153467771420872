import axios from 'axios';
import authHeaderForm from './authheaderform';

let server_URL = process.env.REACT_APP_HOST_RAILS_API

const httpForm = axios.create({ 
	baseURL: server_URL,
    //headers: authHeaderForm() 
})

httpForm.interceptors.request.use(function (config) {
    const accessToken = JSON.parse(localStorage.getItem('token'));
    config.headers.Authorization =  'Bearer ' + accessToken;
    return config;
});

export default httpForm