import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TrelloIcon } from '../icon/trelloIcon';
import { TableHead } from '@mui/material';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                     aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};




export default function EventTable(props) {

    const rows = props.list
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const selectedRow = (id) => {
        props.handleCellClick(id)
    }

    React.useEffect(() => {
        setPage(0)
        setRowsPerPage(6)
      }, [rows]);
      
    return (

        <Paper sx={{ width: '100%' }}>
            <TableContainer style={{ height: "55vh", boxShadow: "none" }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Date</b></TableCell>
                            <TableCell><b>Clients</b></TableCell>
                            <TableCell><b>Sujets</b></TableCell>
                            <TableCell><b>Montant</b></TableCell>
                            <TableCell align="center"><b>Trello</b></TableCell>
                        </TableRow>
                    </TableHead>
                    {rows.length==0 ? 
                        <TableRow>
                            <TableCell colSpan={5} style={{ textAlign: "center" }} > Aucun évènement trouvé. </TableCell>
                        </TableRow> :
                        <TableBody>
                            {(rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map((row) => (
                                <TableRow hover key={row.id}>
                                    <TableCell style={{ cursor: "pointer" }} onClick={() => selectedRow(row.id)}>
                                        {row.date}
                                    </TableCell>
                                    <TableCell style={{ cursor: "pointer",width: "40%"  }}  onClick={() => selectedRow(row.id)}>
                                        {row.clients.split(";").map((client) => (<>{client} <br /></>))}
                                    </TableCell>
                                    <TableCell style={{ cursor: "pointer",width: "25%"  }} onClick={() => selectedRow(row.id)}>
                                        {row.subject.split(";").map((subject) => (<>{subject} <br /></>))}
                                    </TableCell>
                                    <TableCell style={{ cursor: "pointer",width: "15%"  }}  onClick={() => selectedRow(row.id)} >
                                        {row.montant}
                                    </TableCell>

                                    <TableCell align="center" style={{ width: "10%"  }} >
                                        {row.lienTrello && <a target="_blank" href={row.lienTrello}><TrelloIcon width="20px" height="20px" /> </a>}
                                    </TableCell>
                                </TableRow>
                            ))}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[6, 10, 25, { label: 'Tout', value: -1 }]}
                colSpan={5}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: {
                        'aria-label': 'lignes par page',
                    },
                    native: true,
                }}
                style={{ display: "flex", justifyContent: "end", alignContent: "center", width: "100%" }}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${from}–${to} sur ${count !== -1 ? count : `plus que ${to}`}`;
                }}
                labelRowsPerPage='Lignes par page'
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </Paper>
    );
}
