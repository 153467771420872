import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';




import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';


import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';


import { Link } from 'react-router-dom';
import { logoutUser } from "../actions/logoutUser";
import { connect } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';

 function NavBarShow(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [navChange, setNavChange] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [anchorsEl, setAnchorsEl] = React.useState(null);
  let open = Boolean(anchorsEl);
  const handleMnClick = (event) => {
    setAnchorsEl(event.currentTarget);
  };
  const handleMnClose = () => {
    setAnchorsEl(null);
  };
  

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
  
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  
  const handleLogOut = () => {
    props.logoutUser();
    handleMenuClose();
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style = {{top: "52px"}}
    >
      <MenuItem onClick={handleLogOut}>Se déconnecter</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      style = {{top: "52px"}}
    >
      <MenuItem onClick={handleLogOut}>Se déconnecter</MenuItem>
    </Menu>
  );
  // const matches = useMediaQuery('(min-width:980px)');

  // if(matches){
  //   open=true;
  // }
  const matches = useMediaQuery('(min-width:980px)');
  



  return (
   <Box sx={{ flexGrow: 1 }} style={{width: "100%"}}>
       {!matches && <><AppBar position="static">
        <Toolbar>
          
          {props.isLoggedIn &&<>
            
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              
              id="basic-button"
              
              sx={{ mr: 2 }}
              component={Link} 
              to="/evenements"
              onClick={()=> {let elements = document.getElementsByClassName("navigationBar")
              if(elements && elements.length>0){
                  elements[0].style.display = 'flex';
              }
              }}
              
              
              
            >
              <ArrowBackIcon/>
            </IconButton>
            </>
          }
          <Box sx={{ flexGrow: 1 }} />
         
        </Toolbar>
      </AppBar>
      
        </>}
    </Box>
        
  );
}

export default connect(null, { logoutUser })(NavBarShow);