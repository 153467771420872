import * as React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { contactsList, clientsList, subjectList } from "../actions/eventGRC";
import { connect } from "react-redux";
import { Component } from 'react';
import { contactsEvent, deleteContactsEvent } from "../actions/contacts";

class AutocompleteField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      client:{"title":this.props.client.label,"id":this.props.client.id},
      selectionned:0
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillMount() {
    if (this.props.eventEdit_page) {
      if (this.props.clients) {
        const clientSelected = (this.props.clients && this.props.client_edit) ? this.props.clients.filter(option => this.props.client_edit.split(';').includes(option.id)) : ""
        this.setState({
          value: [
            ...clientSelected,
          ]
        });
        this.props.clientsList([
          ...clientSelected,
        ]);
        const user_token = this.props.user
        if (this.props.client_edit.split(';').length > 0) {
          const clients_ids = this.props.client_edit
          this.props.contactsEvent(clients_ids, user_token)
        }
      }
      else if (this.props.sujets) {
        const subjectSelected = this.props.subject_edit ? this.props.sujets.filter(option => this.props.subject_edit.split(';').includes(option.id)) : ""
        this.setState({
          value: [
            ...subjectSelected,
          ]
        });
        this.props.sujetList([
          ...subjectSelected,
        ])
      }
      else if (this.props.contacts) {
        const contactSelected = this.props.contact_edit ? this.props.contacts.filter(option => this.props.contact_edit.split(';').includes(option.identifiant)) : ""
        this.setState({
          value: [
            ...contactSelected,
          ]
        });
        this.props.contactsList([
          ...contactSelected,
        ])
      }

    }else if (this.props.client) {
     
      if (this.props.clients) {
        const clientSelected=this.props.client;
        const clientsSelected = JSON.stringify(clientSelected)!="{}"? [{"title":clientSelected.label,'id':clientSelected.id}] : [];
        this.setState({
          value: [
            ...clientsSelected,
          ]
        });
        this.props.clientsList([
          ...clientsSelected,
        ]);
        const user_token = this.props.user
        this.props.contactsEvent(clientSelected.id, user_token)
        this.setState({selectionned:clientsSelected.length})        
      }
    }
  }
    componentDidMount() {
     if (this.props.eventEdit_page && this.props.clients) {
      const user_token = this.props.user
      const clients_ids = this.props.client_edit
      this.props.contactsEvent(clients_ids, user_token)
    }
  

  }


  componentWillReceiveProps(nextProps) {
    if(nextProps.client != {} && this.props.client != {} ){
      this.setState({ client:{"title":nextProps.client.label,'id':nextProps.client.id}  })
    }
    

  }

  handleChange(event, newValue) {
    this.setState({
      value: [
        ...newValue,
      ]
    });
    switch (this.props.selectLabel) {

      case "Contacts":
        this.props.contactsList([
          ...newValue,
        ])
        break;
      case "Sujets":
        this.props.sujetList([
          ...newValue,
        ])
        break;
      case "Clients":
        const clients_event = [...newValue]
        const user_token = this.props.user
        this.props.clientsList([
          ...newValue,
        ])
        if (clients_event.length > 0) {
          const clients_ids = clients_event.map(client => client.id).join(';')
          this.props.contactsEvent(clients_ids, user_token)
        }
        else {
          this.props.deleteContactsEvent()
        }
        this.setState({selectionned:clients_event.length})

      break;
    }

  }


  render() {
    let value = this.state.value
    const fixedOptions = "";
    const clientSelected=this.state.client?this.state.client:null
    let listoptions = []
    if (this.props.clients) {
      listoptions = this.props.clients;
      if (this.props.eventEdit_page || clientSelected ) {
        const clientsselected = value.map(client => client.id)
        listoptions = listoptions.filter(client => !clientsselected.includes(client.id))
      }
    }
    let labelselect = this.props.selectLabel
    if (this.props.sujets) {
      listoptions = this.props.sujets;
      if (this.props.eventEdit_page) {
        const selected = value.map(subject => subject.id)
        listoptions = listoptions.filter(subject => !selected.includes(subject.id))
      }
    }
   
    if (this.props.contacts) {
      listoptions = this.props.contacts;
      if (this.props.eventEdit_page) { 
        const selected = value.map(contact => contact.id)
        listoptions = listoptions.filter(contact => !selected.includes(contact.id))     
       }
    }






    return (
      <>
      <label id = "labelForm2" >{labelselect+" "}<span style={{fontWeight:600}}>{this.state.selectionned>0 && !this.props.eventEdit_page && (" - "+this.state.selectionned+" sélectionnés -")}</span></label><br/>
      
        <Autocomplete
          multiple
          id="fixed-tags-demo"
          className={"selectForm"}
          defaultValue={value}
          onChange={this.handleChange}
          options={listoptions}
          
          getOptionLabel={(option) => option.title}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.title}
                {...getTagProps({ index })}

              />
            ))
          }
          //   style={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="" placeholder="" />
          )}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clientsList: (formDAta) => dispatch(clientsList(formDAta)),
    sujetList: (formDAta) => dispatch(subjectList(formDAta)),
    contactsList: (formDAta) => dispatch(contactsList(formDAta)),
    contactsEvent: (formDAta, formData) => dispatch(contactsEvent(formDAta, formData)),
    deleteContactsEvent: () => dispatch(deleteContactsEvent()),
  };
};
const mapStateToProps = ({ eventGRC, usersReducer }) => {
  return {
    event: {
      clients: eventGRC.clients,
      contacts: eventGRC.contacts,
      sujet: eventGRC.sujet
    },
    user: usersReducer.user,
    client : eventGRC.client
  };

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutocompleteField);
