import { CONTACTS_LIST, CONTACTS_EVENT } from "./types";
import http from '../lib/axios'

export function contactsEvent(clientId) {
  return (dispatch) => {
    http.get(`/contacts/${clientId}`)
      .then(res => {
        const data = res.data
        dispatch({
              type: CONTACTS_LIST,
              payload: {
                 contacts: data.contacts,
              },
            })
         
      });
  };
}

export function addContactsEvent(contacts) {
  return (dispatch) => {
    dispatch({
      type: CONTACTS_EVENT,
      payload: {
         contacts: contacts,
      },
    })
  };
}

export function deleteContactsEvent() {
  return (dispatch) => {
    dispatch({
      type: CONTACTS_LIST,
      payload: {
         contacts: [],
      },
    })
  };
}