import { CONTACTS, CLIENTS, SUJET, ACTION_EVENT } from "./types";


export function contactsList(contacts) {
  
    return (dispatch) => {
        dispatch({
            type: CONTACTS,
            payload: {
                contacts: contacts,
            },
            })
    };
  
}

export function clientsList(client) {
    return (dispatch) => {
        dispatch({
            type: CLIENTS,
            payload: {
                clients: client,
            },
            })
    };
}

export function subjectList(subject) {
    return (dispatch) => {
        dispatch({
            type: SUJET,
            payload: {
                sujet: subject,
            },
            })
    };
} 

export function loadingToggleAction(status) {
    return (dispatch) => {
        dispatch({
            type: ACTION_EVENT.LOADING_TOGGLE_ACTION,
            payload: status,
        })
    };
}