import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { connect } from "react-redux";

import { WarningIcon } from '../icon/warningIcon';
import { deleteEvent } from '../../actions/events';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: "16px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};


class ConfirmationModel extends React.Component {
  constructor(props) {
    super(props);

  }

  handleDelete=(e)=> {
    
    const user = this.props.user;
    const event_json = {
      idEvenement: this.props.idEvenement,
      cac_id: this.props.idUser,
    };
    e.preventDefault();
    this.props.deleteEvent(event_json);
    this.setState({
      btn_disabled: true,
      btn_text: "en cours...",
      text_changed: true
    });


    this.setState({
      send_valid: true,
    });
  }

  render() {

    return (
      <div>

        <Modal
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style }}>
            <div style={{ textAlign: "center" }}><WarningIcon color={"#D32F2F"} width="64" height="64" /></div>
            <h4 id="parent-modal-title" style={{ margin: "12px 0" }}>
              Vous êtes sur le point de supprimer cet événement
            </h4>
            <p id="parent-modal-description">
              Êtes-vous sûr de vouloir continuer ?
            </p>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button onClick={this.props.handleClose} style={{ color: " var(--secondary)", marginRight: "12px" }}>Annuler</Button>
              <Button onClick={this.handleDelete} variant="contained" color="error">Supprimer</Button>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    
    deleteEvent: (formDAta) => dispatch(deleteEvent(formDAta, ownProps)),

  };
};
export default connect(null, mapDispatchToProps)(ConfirmationModel);
