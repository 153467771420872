import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { monumentRequest } from '../actions/monument';
import {Component} from 'react';
import { connect } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import { messageLogin } from "../actions/loginUser";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

class MonumentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nb_request: 1,
      request_type: "",
      family_reference: "",
      description: "",
      request_attachement: [],
      send_valid: false,
      client:null,
      open_alert:false,
      btn_text: "Envoyer la demande",
      btn_disabled: false,
      text_changed:false
      
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }
  removeFile(ind){
    const input = document.getElementById("file");
    let attachement = Array.from(this.state.request_attachement);
    const dt = new DataTransfer()
    if(attachement.length == 1){
      attachement = []
      input.files = dt.files
    }
    else{
      const attachs = []

      for (let i = 0; i < attachement.length; i++) {
        if(i!==ind.i){
          const file = attachement[i]
          attachs.push(file)
          dt.items.add(file)
        }
         // here you exclude the file. thus removing it.
      }
      attachement = attachs
      
    }
    
    
    input.files = dt.files
    this.setState({request_attachement: attachement})

  }
  componentDidMount() {
    if(this.props.client){
      const clientSelected=this.props.client;
      this.setState({ client: clientSelected })
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ client: nextProps.client })
  }
  showAttachedFiles(){
    const files = this.state.request_attachement;
    const FilesContainer = document.getElementById("attachContainer");
    if (FilesContainer) FilesContainer.innerHTML="";
    
  }
    

  handleChange (e){
    
    if(e.target.name !== 'request_attachement'){
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
    else{
      let files = e.target.files;
      
      
      this.setState({
        [e.target.name]: files,
      })
     
    }
   
  }
  handleSubmit(e){
    
    const {nb_request,description,request_type,request_attachement,family_reference,client}=this.state;


    e.preventDefault();
   
    let formData = new FormData();

    if(request_type !=="" && description !=="" && family_reference !=="" && client !== null){
      formData.append('description',description)
    formData.append('request_type',request_type)
    formData.append('family_reference',family_reference)
    formData.append('client[nomClient]',client.label)
    formData.append('client[codeClient]',client.id)
    formData.append('nb_request',nb_request)
    Array.from(request_attachement).forEach((item) => {
      formData.append('attachments[]', item);
      
  });
     this.props.monumentRequest(formData);
     this.setState({
      btn_disabled: true,
      btn_text: "en cours...",
      text_changed:true
    });
    }
    this.setState({
      send_valid: true,
  });
  }
  render(){
    
    let open_alert = this.state.open_alert
    let btn_text = this.state.btn_text
    let text_changed= this.state.text_changed
    if(this.props.message_error && this.state.send_valid){
      open_alert= true;
      btn_text= "Envoyer la demande";
      text_changed = false;
    }
    const valid_button = this.state.btn_disabled
    const pdgBottom = "16px";
    let clients = (this.props.store.user.params.clients.length > 0)? 
        this.props.store.user.params.clients.map(client=>{
            return {label: client.nomClient +" - "+ client.codeClient, id: client.codeClient}
        }) : [];

    const clientSelected = JSON.stringify(this.state.client)!="{}"?this.state.client:null;
    return (
      <div className="div-app">
      <div className="monumentForm" style={{textAlign:"center"}}>
        <Box component="form" noValidate autoComplete="off" sx={{ minWidth: 120 }}>
          <h3>Demande sur monument</h3>
          <FormControl fullWidth style={{}} id={(this.state.send_valid && this.state.client==null)?"field_error":""}>
                <FormLabel  variant="standard" id="labelForm2" >Client</FormLabel><br/>
                <Autocomplete
                id="combo-box-demo"
                options={clients}
                name="clientId"
                blurOnSelect
                value={clientSelected}
                className="inputForm select align-items-center"
                renderInput={(params) => <TextField {...params} label="" variant="standard" style={{marginLeft:"4px"}}/>}
                onFocus={()=>{
                  this.setState({send_valid:false,open_alert:false,btn_text:"Envoyer la demande",
                  text_changed:false,btn_disabled:false});
                    this.props.messageLogin();}}
                onChange={(event, newValue)=>this.setState({client:newValue})}
                />

                    {this.state.send_valid && !this.state.client?.id &&
                        <span className="text-error">*Client est obligatoire</span>
                    } <br/>
          </FormControl>
          <br/>
          <FormControl fullWidth style={{lineHeight: "18px",paddingBottom: pdgBottom}}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
            Nombre de demandes
                </InputLabel><br/>
            <NativeSelect style={{marginTop:"5px"}}
            id="demo-customized-select-native"
            defaultValue={1}
            value ={this.state.nb_request}
            name = "nb_request"
            onChange={this.handleChange}
            input={<BootstrapInput />}
            >
        
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
            </NativeSelect>
          </FormControl>
          <br/>
          <FormControl fullWidth style={{paddingBottom: pdgBottom}} id={this.state.send_valid && 
                    this.state.request_type===""?"field_error":""}>
            <FormLabel  variant="standard" id="labelForm2" >Type</FormLabel><br/>

            <RadioGroup
            onChange={this.handleChange}
                aria-label="type"
                defaultValue="Nouvelle commande"
                name="request_type"
                value={this.state.request_type}
                onFocus={()=>{
                  this.setState({send_valid:false,open_alert:false,btn_text:"Envoyer la demande",
                  text_changed:false,btn_disabled:false});
                    this.props.messageLogin();}}
            >
                <FormControlLabel value="Nouveau devis" control={<Radio />} label="Nouveau devis" />
                <FormControlLabel value="Nouvelle commande" control={<Radio />} label="Nouvelle commande" />
                <FormControlLabel value="Modification de commande" control={<Radio />} label="Modification de commande" />
                <FormControlLabel value="Modification de devis" control={<Radio />} label="Modification de devis" />
                <FormControlLabel value="SAV" control={<Radio />} label="SAV" />
            </RadioGroup>
            {this.state.send_valid &&  this.state.request_type==="" &&
                        <span className="text-error">*Le type est obligatoire</span>
                    }  
          </FormControl>
          <br/>
          <FormControl fullWidth style={{lineHeight:"12px",paddingBottom: pdgBottom}} id={this.state.send_valid && 
                    this.state.family_reference===""?"field_error":""}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native" 
            value={this.state.family_reference} name="family_reference" id="labelForm2" >
            Référence famille
            </InputLabel><br/>
            
        
            <input  type="text" className="inputForm familyref" placeholder="" name="family_reference"
             value={this.state.family_reference} onChange={this.handleChange}   onFocus={()=>{
              this.setState({send_valid:false,open_alert:false,btn_text:"Envoyer la demande",
              text_changed:false,btn_disabled:false});
                this.props.messageLogin();}}/>
              {this.state.send_valid &&  this.state.family_reference==="" &&
                        <span className="text-error">*Le champs référence famille est obligatoire</span>
                    } 
          </FormControl>
          <br/>
          
          <FormControl fullWidth style={{paddingBottom: pdgBottom}}id={this.state.send_valid && 
                    this.state.description===""?"field_error":""}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native" 
            value={this.state.description} name="description" id="labelForm2">
            Description
            </InputLabel><br/>
            
        
            <TextareaAutosize  minRows={3} className="textareaForm" placeholder="" name="description" 
            value={this.state.description} onChange={this.handleChange}  onFocus={()=>{
              this.setState({send_valid:false,open_alert:false,btn_text:"Envoyer la demande",
              text_changed:false,btn_disabled:false});
                this.props.messageLogin();}} />
              {this.state.send_valid && this.state.description==="" &&
                        <span className="text-error">*La description est obligatoire</span>
                    }  
          </FormControl>
          <br/>
          <FormControl fullWidth style={{paddingBottom: "0"}}>
            <InputLabel variant="standard" htmlFor="uncontrolled-native"  id="labelForm2">
            Pièces jointes <span className="facultatif">(optionnel)</span>
            </InputLabel><br/>
            <Button style={{marginTop:"3px"}}
            variant="outlined"
            component="label"
            id="button-secondary"
            >
            Joindre un fichier
            <input
            onChange={this.handleChange}
            id = "file"
            name="request_attachement"
                type="file"
                hidden
            multiple={true}/>
            </Button>
            <div id="attachContainer" className="attachedFilesContainer">
            {Array.from(this.state.request_attachement).map((file,i)=>
      <div className='attachedFile'><span className='attachedFileName'>
      {file.name}</span><Button onClick={()=>this.removeFile({i})}>
        <svg key={i} className='MuiSvgIcon-root attachedFileIcon MuiSvgIcon-fontSizeMedium MuiChip-deleteIcon MuiChip-deleteIconMedium MuiChip-deleteIconColorDefault MuiChip-deleteIconOutlinedColorDefault css-i4bv87-MuiSvgIcon-root' focusable='false' viewBox='0 0 24 24' aria-hidden='true' data-testid='CancelIcon'><path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z'></path></svg></Button></div>
            )
          }
                </div>
            
            </FormControl> 
            <FormControl fullWidth style={{marginTop:"16px", lineHeight: "12px", paddingBottom: "32px"}}>
            <Button className="sendButton" variant="contained" onClick={this.handleSubmit} id="button-primary" disabled={valid_button}>
                  {btn_text}
                  {this.state.btn_disabled && text_changed && <img src={`${process.env.PUBLIC_URL}/style/reload.gif`} style={{width:"100px"}}/>}
                  </Button>
          </FormControl> 
        </Box>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    monumentRequest: (formDAta) => dispatch(monumentRequest(formDAta, ownProps)),
    messageLogin: () => dispatch(messageLogin()),
  };
};
const mapStateToProps = ({ eventGRC, usersReducer }) => {
  return {
  contacts: usersReducer.contacts,
  client : eventGRC.client
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(MonumentForm);