import {ACTION_EVENT}  from "./types";
import http from '../lib/axios'


export function reloadingData() {
  const user = JSON.parse(localStorage.getItem('user'));
  const filter_fields= {
    user: user
  }
  return (dispatch) => {
    http.get(`/reload`, {
        params: filter_fields
      }, filter_fields)
      .then(response => {
          dispatch({
            type: ACTION_EVENT.RELOAD_DATA,
            payload: {user: user, params: response.data.params, token: localStorage.getItem('token'), cacId:response.data.cacId, extranet_url: response.data.extranetUrl, trello_url: response.data.trelloBoard},
          }
        )
      }
    ).catch((e)=>{
      console.log("reloading data error ",e)
    });
  }
      
};
