import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { thanatoRequest } from '../actions/monument';
import { Component } from 'react';
import { connect } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import { messageLogin } from "../actions/loginUser";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

class ThanatoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request_type: "Nouveau devis",
      observation: "",
      articlesTable: [],
      allArticles: [],
      client: null,
      send_valid: false,
      open_alert: false,
      btn_text: "Envoyer la demande",
      btn_disabled: false,
      text_changed: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleArticlesChange = this.handleArticlesChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
  }

  componentDidMount() {
    if (this.props.client) {
      const clientSelected = this.props.client;
      this.setState({ client: clientSelected })
    }

    let articlesData = this.props.store?.user?.params?.thanato?.articles?.map((article) => {
      return { label: article.libelleArticle, value: article.idArticle }
    })

    this.setState({ allArticles: articlesData });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleArticlesChange(e, newValue) {
    const articlesTable = newValue.map(article => {
      const existingArticle = this.state.articlesTable.find(a => a.value === article.value);
      return existingArticle ? existingArticle : { ...article, quantity: 1 };
    });
    this.setState({ articlesTable });
  }

  handleQuantityChange(e, index) {
    const articlesTable = [...this.state.articlesTable];
    articlesTable[index].quantity = e.target.value;
    this.setState({ articlesTable });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { request_type, client, observation, articlesTable } = this.state;
    const clientInfos = this.props.store?.user?.params?.clients.find((clientObj) => clientObj.codeClient === client.id);

    if (!this.validateForm()) {
      this.setState({ send_valid: true });
      return;
    }

    const jsonData = {
      observation, request_type, nom_client: clientInfos.nomClient,
      code_client: client.id, adresse_client: clientInfos.adresseClient,
      cpostal_client: clientInfos.cpostalClient, ville_client: clientInfos.villeClient,
      tel_client: clientInfos.telClient, articles: articlesTable
    }

    this.props.thanatoRequest(jsonData);
    this.setState({
      btn_disabled: true,
      btn_text: "en cours...",
      text_changed: true,
      send_valid: true
    });
  }

  validateForm() {
    const {
      request_type, client, observation, articlesTable
    } = this.state;

    if (!request_type || !client.id || !client.label || !observation || articlesTable.length < 1) {
      return false;
    }

    return true;
  }

  // createFormData(data) {
  //   const formData = new FormData();
  //   Object.keys(data).forEach(key => {
  //     if (key === "articlesTable") {
  //       data[key].forEach((article, index) => {
  //         formData.append(`articles[${index}][idArticle]`, article.value);
  //         formData.append(`articles[${index}][quantite]`, article.quantity);
  //         formData.append(`articles[${index}][libelleArticle]`, article.label);
  //       })
  //     } else if (data[key] !== undefined) {
  //       formData.append(key, data[key]);
  //     }
  //   });
  //   return formData;
  // }

  render() {
    let open_alert = this.state.open_alert;
    let btn_text = this.state.btn_text;
    let text_changed = this.state.text_changed;

    if (this.props.message_error && this.state.send_valid) {
      open_alert = true;
      btn_text = "Envoyer la demande";
      text_changed = false;

      // Scroll to the top of the specific div
      const formContainer = document.getElementById('formContainer');
      if (formContainer) {
        formContainer.scrollTop = 0;
      }
    }

    const valid_button = this.state.btn_disabled;
    const pdgBottom = "16px";
    let clients = (this.props.store.user.params.clients.length > 0) ?
      this.props.store.user.params.clients.map(client => {
        return { label: client.nomClient + " - " + client.codeClient, id: client.codeClient }
      }) : [];

    const clientSelected = JSON.stringify(this.state.client) != "{}" ? this.state.client : null;

    return (
      <div className="div-app">
        <div className={`monumentForm equipementForm`} style={{ textAlign: "center" }} id="formContainer">
          <Box component="form" noValidate autoComplete="off" sx={{ minWidth: 120 }}>
            <Collapse in={open_alert}>
              <Alert severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({
                        open_alert: false, btn_text: "Envoyer la demande",
                        text_changed: false, btn_disabled: false, btn_text: "Envoyer la demande",
                        text_changed: false, btn_disabled: false
                      });
                      this.props.messageLogin();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                Une erreur s'est produite, veuillez réessayer ultérieurement.
              </Alert>
            </Collapse>
            <h3>Demande sur thanato</h3>
            <FormControl fullWidth style={{}} id={(this.state.send_valid && this.state.client == null) ? "field_error" : ""}>
              <FormLabel variant="standard" id="labelForm2" >Client</FormLabel><br />
              <Autocomplete
                id="combo-box-demo"
                options={clients}
                name="clientId"
                blurOnSelect
                value={clientSelected}
                className="inputForm select align-items-center"
                renderInput={(params) => <TextField {...params} label="" variant="standard" style={{ marginLeft: "4px" }} />}
                onFocus={() => {
                  this.setState({
                    send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                    text_changed: false, btn_disabled: false
                  });
                  this.props.messageLogin();
                }}
                onChange={(event, newValue) => this.setState({ client: newValue })}
              />

              {this.state.send_valid && !this.state.client?.id &&
                <span className="text-error">*Client est obligatoire</span>
              } <br />
            </FormControl>
            <br />
            <FormControl fullWidth style={{ paddingBottom: pdgBottom }} id={this.state.send_valid &&
              this.state.request_type === "" ? "field_error" : ""}>
              <FormLabel variant="standard" id="labelForm2" >Type</FormLabel><br />

              <RadioGroup
                onChange={this.handleChange}
                aria-label="type"
                defaultValue="Nouveau devis"
                name="request_type"
                value={this.state.request_type}
                onFocus={() => {
                  this.setState({
                    send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                    text_changed: false, btn_disabled: false
                  });
                  this.props.messageLogin();
                }}
              >
                <FormControlLabel value="Nouveau devis" control={<Radio />} label="Nouveau devis" />
                <FormControlLabel value="Nouvelle commande" control={<Radio />} label="Nouvelle commande" />
              </RadioGroup>
              {this.state.send_valid && this.state.request_type === "" &&
                <span className="text-error">*Le type est obligatoire</span>
              }
            </FormControl>
            <br />
            <FormControl fullWidth style={{}} id={(this.state.send_valid && this.state.client == null) ? "field_error" : ""}>
              <FormLabel variant="standard" id="labelForm2" >Articles & quantités</FormLabel><br />
              <Autocomplete
                multiple
                id="combo-box-demo"
                options={this.state.allArticles}
                name="articles"
                blurOnSelect
                className="inputForm select align-items-center multiple-select"
                renderInput={(params) => <TextField {...params} label="" variant="standard" className="multiple-input" style={{ marginLeft: "4px" }} />}
                onFocus={() => {
                  this.setState({
                    send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                    text_changed: false, btn_disabled: false
                  });
                  this.props.messageLogin();
                }}
                onChange={this.handleArticlesChange}
              />

              {this.state.send_valid && this.state.articlesTable?.length < 1 &&
                <span className="text-error">*Vous devez choisir au moins un article</span>
              }
            </FormControl>
            <br />
            {this.state.articlesTable.length > 0 && (
              <TableContainer component={Paper} style={{
                marginTop: "10px"
              }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '80%', padding: "14px 16px" }}>Articles</TableCell>
                      <TableCell align="right" sx={{ width: '20%', padding: "14px 16px" }}>Quantités</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.articlesTable.map((article, index) => (
                      <TableRow key={article.value}>
                        <TableCell component="th" scope="row" sx={{ width: '85%', padding: "7px 16px" }}>
                          {article.label}
                        </TableCell>
                        <TableCell align="right" sx={{ width: '15%', padding: "7px 16px" }}>
                          <TextField
                            type="number"
                            value={article.quantity}
                            onChange={(e) => this.handleQuantityChange(e, index)}
                            InputProps={{
                              inputProps: {
                                style: { textAlign: 'center' },
                                min: 1
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <br />
            <FormControl fullWidth style={{ paddingBottom: pdgBottom }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native"
                value={this.state.observation} name="observation" id="labelForm2">
                Observation
              </InputLabel><br />

              <TextareaAutosize minRows={3} className="textareaForm" placeholder="" name="observation"
                value={this.state.observation} onChange={this.handleChange} onFocus={() => {
                  this.setState({
                    send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                    text_changed: false, btn_disabled: false
                  });
                  this.props.messageLogin();
                }} />
              {this.state.send_valid && this.state.observation === "" &&
                <span className="text-error">*Observation est obligatoire</span>
              }
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "16px", lineHeight: "12px", paddingBottom: "32px" }}>
              <Button className="sendButton" variant="contained" onClick={this.handleSubmit} id="button-primary" disabled={valid_button}>
                {btn_text}
                {this.state.btn_disabled && text_changed && <img src={`${process.env.PUBLIC_URL}/style/reload.gif`} style={{ width: "100px" }} />}
              </Button>
            </FormControl>
          </Box>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    thanatoRequest: (formDAta) => dispatch(thanatoRequest(formDAta, ownProps)),
    messageLogin: () => dispatch(messageLogin()),
  };
};

const mapStateToProps = ({ eventGRC, usersReducer }) => {
  return {
    client: eventGRC.client,
    message_error: usersReducer.message_error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThanatoForm);