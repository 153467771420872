import * as React from 'react';

import Box from '@mui/material/Box';

import { useHistory, useLocation } from 'react-router-dom'

export function Alert(props) {
  let history = useHistory()
  const location = useLocation();
  setTimeout(function(){ history.push("/evenements"); }, 1500);

 
  return (
    <div style={{
      background: "rgb(240, 242, 245)", position: "fixed"
    }} className="alert_success">
      <div className="monumentForm">
        <Box sx={{ flexGrow: 1 }} style={{ width: "100%" }} id="alertsuccess">
          <div style={{
            fontSize: "32px",
            paddingBottom: "20%",
            paddingTop: "30%"
          }}>{location.state.message}</div>
          <img src={`${process.env.PUBLIC_URL}/style/emoji_check.png`} />

        </Box>
      </div>
    </div>
  );
}