import axios from 'axios';
import authHeader from './authheader';

let server_URL = process.env.REACT_APP_HOST_RAILS_API

const http = axios.create({ 
	baseURL: server_URL,
    //headers: authHeader() 
})


http.interceptors.request.use(function (config) {
    const accessToken = JSON.parse(localStorage.getItem('token'));
    config.headers.Authorization =  'Bearer ' + accessToken;
    return config;
});

export default http