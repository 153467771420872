import {ACTION_EVENT}  from "./types";


export function selectedClient(client) {

  return (dispatch) => {
    dispatch({
        type: ACTION_EVENT.CLIENT_SELECTED,
        payload: client,
      })
  }
      
};
