import * as React from 'react';
import Box from '@mui/material/Box';


import InputBase from '@mui/material/InputBase';

import { Component } from 'react';
import { connect } from "react-redux";

import { getEvents, getEvent, addFilterEvent } from "../actions/events";
import SearchIcon from '@mui/icons-material/Search';

import { styled, alpha } from '@mui/material/styles';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import EventTable from './util/eventTable';
import { Grid } from '@mui/material';
import Loader from './util/Loader/loader';
import { loadingToggleAction } from '../actions/eventGRC';

class EventsGRC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            send_valid: false,
            page: 0,
            selectedRows: [],
            user: null,
            client: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentWillMount() {
        this.props.loadingToggleAction(true);

        if (this.props.eventFilter) {
            this.setState({
                user: this.props.eventFilter.userId,
                client: this.props.eventFilter.clientId
            })
            await this.props.getEvents(this.props.eventFilter.userId, this.props.eventFilter.clientId);

        }
        else {
            this.setState({ user: this.props.store.user.cacId })
            await this.props.getEvents(this.props.store.user.cacId, null);
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });

    };
    async filterEvent(userId, filterType, client) {
        this.props.loadingToggleAction(true);
        this.props.addFilterEvent(userId, client)
        await this.props.getEvents(userId, client);

    }
    handleSubmit(e) {

    }
    selectRow(selectedIDs, rows) {
        const { user_events } = this.props
        const selectedRows = rows.filter((row) =>
            selectedIDs.has(row.id),
        );

        this.setState({ selectedRows: selectedRows });

        const event_edit = user_events.find(user_event => user_event.idEvenement === selectedRows[0].id)
        this.props.getEvent(event_edit)

    }

    render() {
        const { user_events } = this.props
        const { params, cacId } = this.props.store.user

        let users = (this.props.store.user.params.users.length > 0) ?
            this.props.store.user.params.users.filter(user => user.isRep).map(user => {
                return { label: user.nom, id: user.idUtilisateur, email: user.email }
            }) : [];
        const user = users.find(user => user.id === this.state.user)

        let clients = (this.props.store.user.params.clients.length > 0) ?
            this.props.store.user.params.clients.map(client => {
                return { label: client.nomClient + " - " + client.codeClient, id: client.codeClient }
            }) : [];
        const client = this.state.client && clients.find(client => client.id === this.state.client)
        const event_list = user_events ? user_events.map((event, index) => {
            const client_list = event.listeClient.split(";").map(client => params.clients.find(
                paramclient => paramclient.codeClient === client))
            const topics = event.listeSujet.split(";").map(sujet => params.sujets.find(
                paramsujet => paramsujet.idSujet === sujet))

            return {
                id: index,
                clients: client_list,
                subjects: topics,
                lienTrello: event.lienTrello,
                idEvenement: event.idEvenement,
                dateEvenement: event.dateEvenement.slice(6, 8) + '/'
                    + event.dateEvenement.slice(4, 6) + '/'
                    + event.dateEvenement.slice(0, 4),
                montant:event.montant
            }

        }) : []
     
        const rows = event_list.map((event, index) => {
            const text1 = event.clients.length > 0 && event.clients.map(client =>
                client && client.nomClient).join(";")
            const text2 = event.subjects.map(subject =>
                subject && subject.nomSujet).join(";")
            return {
                id: event.idEvenement,
                clients: text1,
                subject: text2,
                lienTrello: event.lienTrello,
                date: event.dateEvenement,
                montant:event.montant
            }
        }).filter(row => {
           
            let clients =row.clients || '';
            let sujets =row.subject || '';
            let date =row.date || '';
            return clients.toLowerCase().includes(this.state.search.toLowerCase()) ||
            sujets.toLowerCase().includes(this.state.search.toLowerCase()) ||
            date.toLowerCase().includes(this.state.search.toLowerCase()) });

      

        const handleCellClick = (id) => {
            const selectedIDs = new Set([id]);
            this.selectRow(selectedIDs, rows)
        }
        return (
            
            <div className="div-app">

                <div className="monumentForm eventsGRCForm" style={{ textAlign: "center" }}>
                    <Box component="form" noValidate autoComplete="off" sx={{ minWidth: 120 }} >
                        <h3>La liste des événements</h3>
                        <Grid container spacing={3} style={{marginBottom:"20px"}}>

                            <Grid item xs={12} md={4} style={{ textAlign: "left",paddingTop:"10px" }}>
                                <label id="labelForm" >Représentant</label>
                                <Autocomplete
                                    id="combo-box-demo"
                                    disableClearable={this.state.client === null}
                                    options={users}
                                    defaultValue={user}
                                    className="inputForm select align-items-center"
                                    name="userId"
                                    blurOnSelect
                                    renderInput={(params) => <TextField {...params} label="" variant="standard" style={{ marginLeft: "4px" }} />}
                                    onFocus={() => {
                                        this.setState({
                                            send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                                            text_changed: false, btn_disabled: false
                                        });
                                    }}
                                    onChange={(event, newValue) => {
                                        this.setState({ user: (newValue) ? newValue.id : null });
                                        this.filterEvent((newValue) ? newValue.id : null, "user", this.state.client)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} style={{ textAlign: "left",paddingTop:"10px" }}>
                                <label id="labelForm" >Client</label>
                                <Autocomplete
                                    id="combo-box-demo"
                                    disableClearable={this.state.user === null}
                                    defaultValue={client}
                                    className="inputForm select align-items-center"
                                    options={clients}
                                    name="clientId"
                                    blurOnSelect
                                    renderInput={(params) => <TextField {...params} label="" variant="standard" style={{ marginLeft: "4px" }} />}
                                    onFocus={() => {
                                        this.setState({
                                            send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                                            text_changed: false, btn_disabled: false
                                        });
                                    }}
                                    onChange={(event, newValue) => {
                                        this.setState({ client: (newValue) ? newValue.id : null });
                                        this.filterEvent(this.state.user, "client", (newValue) ? newValue.id : null);
                                        event.currentTarget.blur = true
                                    }}
                                />
                            </Grid>
                            <Grid  item xs={12} md={4} style={{ textAlign: "left",paddingTop:"10px" }}>
                                <label id="labelForm" className="search">&nbsp;</label>
                                <Search className="inputForm" style={{ margin: "0" }}>
                                    <SearchIconWrapper className="iconsearch">
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Recherche…"
                                        inputProps={{ 'aria-label': 'search' }}
                                        value={this.state.search}
                                        className={"searchbox "}
                                        onChange={(e) => this.setState({ search: e.target.value })}
                                    />
                                </Search>
                            </Grid>
                        </Grid>
                        {this.props.showLoading ? <Loader />
                        :
                        <EventTable list={rows} handleCellClick={handleCellClick}  />}
                        <br />

                    </Box>

                </div>
            </div>
        );
    }

}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})
);
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
    },
}));
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getEvents: (formDAta, formData) => dispatch(getEvents(formDAta, formData, ownProps)),
        getEvent: (formDAta) => dispatch(getEvent(formDAta, ownProps)),
        addFilterEvent: (formDAta, formDAtaa) => dispatch(addFilterEvent(formDAta, formDAtaa)),
        loadingToggleAction: (status) => dispatch(loadingToggleAction(status))
    };
};
const mapStateToProps = ({ eventGRC, usersReducer }) => {
    return {
        event: {
            clients: eventGRC.clients,
            contacts: eventGRC.contacts,
            sujet: eventGRC.sujet,
        },
        contacts: usersReducer.contacts,
        user_events: usersReducer.user_events,
        eventFilter: usersReducer.eventFilter,
        showLoading: usersReducer.showLoading,

    };

};


export default connect(mapStateToProps, mapDispatchToProps)(EventsGRC);