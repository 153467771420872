import { ADD_MONUMENT, ADD_EQUIPEMENT, SHOW_MESSAGE, LOGOUT } from "./types";
import httpForm from "../lib/axios/httpform";

export function monumentRequest(monument, ownProps) {
  return (dispatch) => {
    return httpForm.post(`/monument_request`, monument)
      .then((response) => {
        response.status !== 500 ?

          dispatch({
            type: ADD_MONUMENT,
            payload: {
              show_message: true,
            },

          }, ownProps.history.push({
            pathname: "/alertsuccess",
            state: { message: 'Votre demande a bien été envoyée' }
          })) :
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              show_message: false,
            },
          })

      }).catch((e) => {
        dispatch({
          type: LOGOUT
        }, ownProps.history.push("/login"))
      });
  };
}

export function equipementRequest(equipementInfos, ownProps) {
  return (dispatch) => {
    return httpForm.post(`/equipement_request`, equipementInfos)
      .then((response) => {
        response.status !== 500 ?
          dispatch({
            type: ADD_EQUIPEMENT,
            payload: {
              show_message: true,
            },
          }, ownProps.history.push({
            pathname: "/alertsuccess",
            state: { message: 'Votre demande a bien été envoyée' }
          })) :
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              show_message: false,
            },
          })
      }).catch((e) => {
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
             show_message: false,
             message_error: true,
          },
        })
      });
  };
}

export function thanatoRequest(equipementInfos, ownProps) {
  return (dispatch) => {
    return httpForm.post(`/thanato_request`, equipementInfos)
      .then((response) => {
        response.status !== 500 ?
          dispatch({
            type: ADD_EQUIPEMENT,
            payload: {
              show_message: true,
            },
          }, ownProps.history.push({
            pathname: "/alertsuccess",
            state: { message: 'Votre demande a bien été envoyée' }
          })) :
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              show_message: false,
            },
          })
      }).catch((e) => {
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
             show_message: false,
             message_error: true,
          },
        })
      });
  };
}