import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';

import FormLabel from '@mui/material/FormLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import AutocompleteField from './autocompleteField';
import { Component } from 'react';
import { connect } from "react-redux";
import { contactsEvent, addContactsEvent } from "../actions/contacts";
import { addEvent, initializeEvents } from "../actions/events";
import Autocomplete from '@mui/material/Autocomplete';
import { messageLogin } from "../actions/loginUser";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';
import DatePicker from '@mui/lab/DatePicker';
import { Stack } from '@mui/material';


class GrcEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hour: '00',
            minute: '00',
            canal: '',
            send_valid: false,
            trello: '',
            comment: '',
            open_alert: false,
            btn_text: "Ajouter l'événement",
            btn_disabled: false,
            text_changed: false,
            date: new Date(),
            amount: ''

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {

        // this.props.sessionStatus();
        if(!this.props.client)
            this.props.initializeEvents();
        // this.props.contacts=[]


    }
    componentDidUpdate() {
        const contacts_list = this.props.contacts && this.props.contacts.map(contact =>
            contact.identifiant)
        // this.props.event.contacts.filter(contact=>
        //     contacts_list.includes(contact.identifiant))            
        const contacts = this.props.event.contacts.filter(contact =>
            contacts_list.includes(contact.identifiant))

        if (contacts.length < this.props.event.contacts.length) {
            this.props.addContactsEvent(contacts)
        }

    }
    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
            send_valid: false,
            open_alert: false,
            btn_text: "Ajouter l'événement",
            text_changed: false,
            btn_disabled: false
        });



    };
    handleSubmit(e) {
        const clients_event = this.props.event.clients.map(client => client.id).join(';')
        
        const contacts_event = this.props.event.contacts.map(contact => contact.identifiant).join(';')
        const sujets_event = this.props.event.sujet.map(subject => subject.id).join(';')
        const { canal, hour, minute, trello, comment, amount } = this.state
        let heure = hour
        let minutes = minute

        if (canal !== "" && (hour !== "00" || minute !== "00") && this.props.event.clients.length > 0
            && this.props.event.contacts.length > 0 && this.props.event.sujet.length > 0) {
            if (parseInt(hour) < 10 && parseInt(hour) > 0) {
                heure = "0" + parseInt(hour)
            }
            if (parseInt(minute) < 10 && parseInt(minute) > 0) {
                minutes = "0" + parseInt(minute)
            }


            const duration = heure + '' + minutes
            const user = this.props.store.user;
            const day = this.state.date.getDate() < 10 ? "0" + this.state.date.getDate() : this.state.date.getDate()
            const month = this.state.date.getMonth() + 1 < 10 ? "0" + (this.state.date.getMonth() + 1) : (this.state.date.getMonth() + 1)
            const dateEvent = this.state.date.getFullYear() + "" + month + "" + day

            const event_json = {
                canal_id: canal,
                topic_list: sujets_event,
                event_duration: duration,
                event_comment: comment,
                link_trello: trello,
                client_list: clients_event,
                contact_list: contacts_event,
                user_id: user.user.id,
                event_date: dateEvent,
                montant: amount,
            };
            e.preventDefault();


            this.props.addEvent(event_json, user);
            this.setState({
                btn_disabled: true,
                btn_text: "en cours...",
                text_changed: true
            });


        }
        this.setState({
            send_valid: true,
        });
    }
    render() {
        let open_alert = this.state.open_alert
        let btn_text = this.state.btn_text
        let text_changed = this.state.text_changed
        if (this.props.message_error && this.state.send_valid) {
            open_alert = true;
            btn_text = "Envoyer la demande";
            text_changed = false;
        }
        const valid_button = this.state.btn_disabled
        const subjects = this.props.store.user.params.sujets


        let sujets = (this.props.store.user.params.sujets.length > 0) ?
            this.props.store.user.params.sujets.map(subject => {
                return { title: subject.nomSujet, id: subject.idSujet }
            }) : [];

        let contacts = (this.props.contacts && this.props.contacts.length > 0) ?
            this.props.contacts.map(contact => {
                return {
                    identifiant: contact.identifiant,
                    title: contact.nomContact,
                    id: contact.codeClient
                }
            }) : [];


        let clients = (this.props.store.user.params.clients.length > 0) ?
            this.props.store.user.params.clients.map(client => {
                return { title: client.nomClient + " - " + client.codeClient, id: client.codeClient, parent: client.parent }
            }) : [];

        const canaux = this.props.store.user.params.canaux.map(canal => {
            return {
                label: canal.nomCanal.charAt(0).toUpperCase() + canal.nomCanal.slice(1).toLowerCase(),
                id: canal.idCanal
            }
        })
        const contactselected = this.props.event.contacts.map(contact =>
            contact.identifiant)
        const contactsNonSelected = contacts.filter(contac => !contactselected.includes(contac.identifiant))
        

        const clientselected = this.props.event.clients.map(clientt =>
            clientt.id)
        const clientsNonselected = clients.filter(client => !clientselected.includes(client.id))
        sujets.filter(sujet => !this.props.event.clients.map(subject =>
            subject.id).includes(sujet.id)
        )

        return (
            <div className="div-app">
                <div className="monumentForm" style={{ textAlign: "center" }}>
                    <Box component="form" noValidate autoComplete="off" sx={{ minWidth: 120 }}>
                        <h3>Ajouter un événement GRC</h3>
                        <FormControl fullWidth style={{ lineHeight: "24px", paddingBottom: "16px" }} id={this.state.send_valid &&
                            this.props.event.clients.length === 0 ? "field_error" : ""}>
                            <AutocompleteField clients={clientsNonselected} selectLabel="Clients" onFocus={() => {
                                this.setState({
                                    send_valid: false, open_alert: false, btn_text: "Ajouter l'événement",
                                    text_changed: false, btn_disabled: false
                                });
                                this.props.messageLogin();
                            }} />
                            {this.state.send_valid && this.props.event.clients.length === 0 &&
                                <span className="text-error">*Champs clients est obligatoire</span>
                            }
                        </FormControl>
                        <FormControl fullWidth style={{ lineHeight: "24px", paddingBottom: "16px" }} id={this.state.send_valid &&
                            this.props.event.contacts.length === 0 ? "field_error" : ""}>

                            <AutocompleteField contacts={contactsNonSelected} contactEvent={this.props.event.contacts} selectLabel="Contacts" onFocus={() => {
                                this.setState({
                                    send_valid: false, open_alert: false, btn_text: "Ajouter l'événement",
                                    text_changed: false, btn_disabled: false
                                });
                                this.props.messageLogin();
                            }} />
                            {this.state.send_valid && this.props.event.contacts.length === 0 &&
                                <span className="text-error">*Champs contacts est obligatoire</span>
                            }
                        </FormControl>
                        <FormControl fullWidth style={{ lineHeight: "24px", paddingBottom: "16px" }} id={this.state.send_valid &&
                            this.props.event.sujet.length === 0 ? "field_error" : ""}>

                            <AutocompleteField sujets={sujets} selectLabel="Sujets" send_valid={this.state.send_valid} />
                            {this.state.send_valid && this.props.event.sujet.length === 0 &&
                                <span className="text-error">*Champs sujets est obligatoire</span>
                            }
                        </FormControl>
                        <FormControl fullWidth style={{}} id={this.state.send_valid &&
                            this.state.canal === "" ? "field_error" : ""}>
                            <FormLabel variant="standard" id="labelForm2" >Canal</FormLabel><br />
                            <Autocomplete
                                id="combo-box-demo"
                                options={canaux}
                                name="canal"
                                blurOnSelect
                                renderInput={(params) => <TextField {...params} label="" />}
                                onFocus={() => {
                                    this.setState({
                                        send_valid: false, open_alert: false, btn_text: "Ajouter l'événement",
                                        text_changed: false, btn_disabled: false
                                    });
                                    this.props.messageLogin();
                                }}
                                onChange={(event, newValue) => this.setState({ canal: newValue.id })}
                            />

                            {this.state.send_valid && this.state.canal === "" &&
                                <span className="text-error">*Canal est obligatoire</span>
                            } 
                        </FormControl>
                        <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: "17px", display: "-webkit-inline-box" }} id={this.state.send_valid && this.state.hour === "00" && this.state.minute === "00" ? "field_error" : ""}>
                            <div style={{ width: "50%", textAlign: "left" }} className="eventDura">
                                <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ paddingBottom: "16px", position: "relative" }}>
                                    Date
                                </InputLabel>

                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale} >
                                    <DatePicker
                                        label=""
                                        // openTo="year"
                                        // views={['year', 'month', 'day']}
                                        value={this.state.date}
                                        onChange={(newValue) => {
                                            this.setState({ date: newValue });
                                        }}

                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div style={{ width: "50%", textAlign: "left" }}>

                                <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ paddingBottom: "7px", position: "relative" }}>
                                    Durée
                                </InputLabel>
                                <div className="eventDuration" style={{ textAlign: "initial" }}>

                                    <input type="text" className={this.state.minute === "00" && this.state.hour === "00" && this.send_valid ?
                                        "inputForm error_field" : "inputForm"} placeholder="00" name="hour"
                                        onChange={this.handleChange} value={this.state.hour}
                                        style={{ width: "40px", textAlign: "center" }}
                                        onFocus={() => {
                                            this.setState({
                                                send_valid: false, open_alert: false, btn_text: "Ajouter l'événement",
                                                text_changed: false, btn_disabled: false
                                            });
                                            this.props.messageLogin();
                                        }}

                                    />
                                    <span className="durationHourLabel" style={{ fontWeight: 400, fontSize: 16 }}> h </span>
                                    <input type="text" className={this.state.minute === "00" && this.state.hour === "00" && this.send_valid ?
                                        "inputForm error_field" : "inputForm"} placeholder="00" name="minute"
                                        onChange={this.handleChange} value={this.state.minute}
                                        style={{ width: "40px", textAlign: "center" }}
                                        onFocus={() => {
                                            this.setState({
                                                send_valid: false, open_alert: false, btn_text: "Ajouter l'événement",
                                                text_changed: false, btn_disabled: false
                                            });
                                            this.props.messageLogin();
                                        }}

                                    />
                                    <span className="durationMinLabel" style={{ fontWeight: 400, fontSize: 16 }}> min</span>
                                </div>
                                {this.state.send_valid && this.state.minute === "00" && this.state.hour === "00" &&
                                    <span className="text-error">*La durée est obligatoire</span>
                                }  

                            </div>





                        </FormControl>
                    
                        <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: "12px" }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
                                Montant <span className="facultatif">(optionnel)</span>
                            </InputLabel><br />
                            <input type="number" onChange={this.handleChange} className="inputForm" placeholder="" name="amount" value={this.state.amount} />
                            
                        </FormControl>
                        <FormControl fullWidth style={{ lineHeight: "24px", paddingBottom: "16px" }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
                                Commentaire <span className="facultatif">(optionnel)</span>
                            </InputLabel><br />


                            <TextareaAutosize minRows={3} className="textareaForm" placeholder=""
                             name="comment" onChange={this.handleChange} value={this.state.comment} />


                        </FormControl>
                        <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: "32px" }}>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
                                Lien Trello <span className="facultatif">(optionnel)</span>
                            </InputLabel><br />


                            <input type="text" className="inputForm trello" placeholder="" name="trello" 
                            onChange={this.handleChange} value={this.state.trello} />


                        </FormControl>
                        <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: "32px" }} >
                            <Stack direction="row" spacing={1}>
                                <Button variant="contained" onClick={this.handleSubmit} id="button-primary" disabled={valid_button}>
                                    {btn_text}
                                    {this.state.btn_disabled && text_changed && <img src={`${process.env.PUBLIC_URL}/style/reload.gif`} style={{ width: "100px" }} />}
                                </Button>
                            </Stack>
                        </FormControl>
                    </Box>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        contactsList: (formDAta) => dispatch(contactsEvent(formDAta)),
        addEvent: (formDAta) => dispatch(addEvent(formDAta, ownProps)),
        messageLogin: () => dispatch(messageLogin()),
        initializeEvents: () => dispatch(initializeEvents()),
        addContactsEvent: (formDAta) => dispatch(addContactsEvent(formDAta)),
    };
};
const mapStateToProps = ({ eventGRC, usersReducer }) => {
    return {
        event: {
            clients: eventGRC.clients,
            contacts: eventGRC.contacts,
            sujet: eventGRC.sujet,
        },
        contacts: usersReducer.contacts,
        client : eventGRC.client

    };

};


export default connect(mapStateToProps, mapDispatchToProps)(GrcEvent);