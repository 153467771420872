import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { equipementRequest } from '../actions/monument';
import { Component } from 'react';
import { connect } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import { messageLogin } from "../actions/loginUser";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

class EquipementForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maturity: "",
      request_type: "Nouveau devis",
      local_type: "local_1",
      table_number: "",
      positive_temp: "",
      negative_temp: "",
      observation: "",
      dimensions_soins: "",
      dimensions_froide: "",
      dimensions: "",
      questions: "",
      concurrence: "Oui",
      request_attachement: [],
      maturiteProjets: [],
      send_valid: false,
      client: null,
      open_alert: false,
      btn_text: "Envoyer la demande",
      btn_disabled: false,
      text_changed: false

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }
  removeFile(ind) {
    const input = document.getElementById("file");
    let attachement = Array.from(this.state.request_attachement);
    const dt = new DataTransfer()
    if (attachement.length == 1) {
      attachement = []
      input.files = dt.files
    }
    else {
      const attachs = []

      for (let i = 0; i < attachement.length; i++) {
        if (i !== ind.i) {
          const file = attachement[i]
          attachs.push(file)
          dt.items.add(file)
        }
        // here you exclude the file. thus removing it.
      }
      attachement = attachs

    }


    input.files = dt.files
    this.setState({ request_attachement: attachement })

  }
  componentDidMount() {
    if (this.props.client) {
      const clientSelected = this.props.client;
      this.setState({ client: clientSelected })
    }

    let maturiteProjetsList = this.props.store?.user?.params?.thanato?.maturiteProjets?.map((maturite) => {
      return { label: maturite.libelleMaturiteProjet, value: maturite.idMaturiteProjet }
    })

    maturiteProjetsList.unshift({ label: "", value: "" });
    this.setState({ maturiteProjets: maturiteProjetsList })
  }

  showAttachedFiles() {
    const files = this.state.request_attachement;
    const FilesContainer = document.getElementById("attachContainer");
    if (FilesContainer) FilesContainer.innerHTML = "";

  }

  handleChange(e) {
    if (e.target.name !== 'request_attachement') {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } else {
      const newFiles = Array.from(e.target.files);
      const existingFiles = Array.from(this.state.request_attachement);
      const allFiles = existingFiles.concat(newFiles);
  
      this.setState({
        request_attachement: allFiles,
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const {
      maturity, observation, questions, dimensions,
      dimensions_soins, dimensions_froide, request_type,
      local_type, request_attachement, table_number, negative_temp,
      positive_temp, client, concurrence, maturiteProjets
    } = this.state;
    const clientInfos = this.props.store?.user?.params?.clients.find((clientObj) => clientObj.codeClient === client.id);

    const maturity_label = maturiteProjets.find((maturite) => maturite.value === maturity)?.label;

    if (!this.validateForm()) {
      this.setState({ send_valid: true });
      return;
    }

    const formData = this.createFormData({
      observation, dimensions, dimensions_soins, dimensions_froide,
      questions, request_type, local_type, table_number, positive_temp,
      negative_temp, maturity, concurrence, nom_client: clientInfos.nomClient, 
      code_client: client.id, adresse_client: clientInfos.adresseClient, 
      cpostal_client: clientInfos.cpostalClient, ville_client: clientInfos.villeClient, 
      tel_client: clientInfos.telClient, maturity_label, request_attachement
    });

    this.props.equipementRequest(formData);
    this.setState({
      btn_disabled: true,
      btn_text: "en cours...",
      text_changed: true,
      send_valid: true
    });
  }

  validateForm() {
    const {
      request_type, client, observation, maturity, concurrence,
      table_number, positive_temp, negative_temp,
      local_type, dimensions, dimensions_soins, dimensions_froide
    } = this.state;

    if (!request_type) return false;

    if (request_type === "SAV") {
      return client && client.id && client.label && observation;
    } else if (request_type === "Nouveau devis") {
      if (!client || !client.id || !client.label || !maturity || !concurrence || !table_number || !positive_temp || !negative_temp) {
        return false;
      }
      if (local_type === "local_1") {
        return !!dimensions;
      } else if (local_type === "local_2") {
        return dimensions_soins && dimensions_froide;
      }
    }
    return true;
  }

  createFormData(data) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (key === 'request_attachement') {
        Array.from(data[key]).forEach((item) => {
          formData.append('attachments[]', item);
        });
      } else if (data[key] !== undefined) {
        formData.append(key, data[key]);
      }
    });
    return formData;
  }

  render() {
    let open_alert = this.state.open_alert;
    let btn_text = this.state.btn_text;
    let text_changed = this.state.text_changed;

    if (this.props.message_error && this.state.send_valid) {      
      open_alert = true;
      btn_text = "Envoyer la demande";
      text_changed = false;
      
      // Scroll to the top of the specific div
      const formContainer = document.getElementById('formContainer');
      if (formContainer) {
        formContainer.scrollTop = 0;
      }
    }

    const valid_button = this.state.btn_disabled;
    const pdgBottom = "16px";
    let clients = (this.props.store.user.params.clients.length > 0) ?
      this.props.store.user.params.clients.map(client => {
        return { label: client.nomClient + " - " + client.codeClient, id: client.codeClient }
      }) : [];

    const clientSelected = JSON.stringify(this.state.client) != "{}" ? this.state.client : null;
    const dimensions_infos = <p style={{
      color: "rgba(0, 0, 0, 0.6)",
      textAlign: "left",
      marginTop: "24px",
    }}>(largeur / profondeur/ hauteur sous plafond en cm + spécificités éventuelles) (joindre un plan idéalement)</p>

    return (
      <div className="div-app">
        <div className={`monumentForm ${this.state.request_type === "SAV" && "equipementForm"}`} style={{ textAlign: "center" }} id="formContainer">
          <Box component="form" noValidate autoComplete="off" sx={{ minWidth: 120 }}>
            <Collapse in={open_alert}>
              <Alert severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({
                        open_alert: false, btn_text: "Envoyer la demande",
                        text_changed: false, btn_disabled: false, btn_text: "Envoyer la demande",
                        text_changed: false, btn_disabled: false
                      });
                      this.props.messageLogin();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                Une erreur s'est produite, veuillez réessayer ultérieurement.
              </Alert>
            </Collapse>
            <h3>Demande sur équipement</h3>
            <FormControl fullWidth style={{}} id={(this.state.send_valid && this.state.client == null) ? "field_error" : ""}>
              <FormLabel variant="standard" id="labelForm2" >Client</FormLabel><br />
              <Autocomplete
                id="combo-box-demo"
                options={clients}
                name="clientId"
                blurOnSelect
                value={clientSelected}
                className="inputForm select align-items-center"
                renderInput={(params) => <TextField {...params} label="" variant="standard" style={{ marginLeft: "4px" }} />}
                onFocus={() => {
                  this.setState({
                    send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                    text_changed: false, btn_disabled: false
                  });
                  this.props.messageLogin();
                }}
                onChange={(event, newValue) => this.setState({ client: newValue })}
              />

              {this.state.send_valid && !this.state.client?.id &&
                <span className="text-error">*Client est obligatoire</span>
              } <br />
            </FormControl>
            <br />
            <FormControl fullWidth style={{ paddingBottom: pdgBottom }} id={this.state.send_valid &&
              this.state.request_type === "" ? "field_error" : ""}>
              <FormLabel variant="standard" id="labelForm2" >Type</FormLabel><br />

              <RadioGroup
                onChange={this.handleChange}
                aria-label="type"
                defaultValue="Nouveau devis"
                name="request_type"
                value={this.state.request_type}
                onFocus={() => {
                  this.setState({
                    send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                    text_changed: false, btn_disabled: false
                  });
                  this.props.messageLogin();
                }}
              >
                <FormControlLabel value="Nouveau devis" control={<Radio />} label="Nouveau devis" />
                <FormControlLabel value="SAV" control={<Radio />} label="SAV" />
              </RadioGroup>
              {this.state.send_valid && this.state.request_type === "" &&
                <span className="text-error">*Le type est obligatoire</span>
              }
            </FormControl>
            <br />
            {this.state.request_type === "SAV" ? (
              <>
                <FormControl fullWidth style={{ paddingBottom: pdgBottom }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native"
                    value={this.state.observation} name="observation" id="labelForm2">
                    Observation
                  </InputLabel><br />


                  <TextareaAutosize minRows={3} className="textareaForm" placeholder="" name="observation"
                    value={this.state.observation} onChange={this.handleChange} onFocus={() => {
                      this.setState({
                        send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                        text_changed: false, btn_disabled: false
                      });
                      this.props.messageLogin();
                    }} />
                  {this.state.send_valid && this.state.observation === "" &&
                    <span className="text-error">*Observation est obligatoire</span>
                  }
                </FormControl>
                <br />
                <FormControl fullWidth style={{ paddingBottom: "0" }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
                    Pièces jointes <span className="facultatif">(optionnel)</span>
                  </InputLabel><br />
                  <Button style={{ marginTop: "3px" }}
                    variant="outlined"
                    component="label"
                    id="button-secondary"
                  >
                    Joindre un fichier
                    <input
                      onChange={this.handleChange}
                      id="file"
                      name="request_attachement"
                      type="file"
                      hidden
                      multiple={true} />
                  </Button>
                  <div id="attachContainer" className="attachedFilesContainer">
                    {Array.from(this.state.request_attachement).map((file, i) =>
                      <div className='attachedFile'><span className='attachedFileName'>
                        {file.name}</span><Button onClick={() => this.removeFile({ i })}>
                          <svg key={i} className='MuiSvgIcon-root attachedFileIcon MuiSvgIcon-fontSizeMedium MuiChip-deleteIcon MuiChip-deleteIconMedium MuiChip-deleteIconColorDefault MuiChip-deleteIconOutlinedColorDefault css-i4bv87-MuiSvgIcon-root' focusable='false' viewBox='0 0 24 24' aria-hidden='true' data-testid='CancelIcon'><path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z'></path></svg></Button></div>
                    )
                    }
                  </div>

                </FormControl>
              </>
            ) : (
              <>
                <FormControl fullWidth style={{ lineHeight: "18px", paddingBottom: pdgBottom }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
                    Maturité du projet
                  </InputLabel><br />
                  <NativeSelect style={{ marginTop: "5px" }}
                    id="demo-customized-select-native"
                    value={this.state.maturity}
                    name="maturity"
                    onChange={this.handleChange}
                    input={<BootstrapInput />}
                  >
                    {
                      this.state.maturiteProjets.map((maturite, index) => {
                        return <option key={index} value={maturite.value}>{maturite.label}</option>
                      })
                    }
                  </NativeSelect>
                  {this.state.send_valid && this.state.maturity === "" &&
                    <span className="text-error">*Maturité est obligatoire</span>
                  }
                </FormControl>
                <br />
                <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: pdgBottom }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native"
                    value={this.state.table_number} name="table_number" id="labelForm2" >
                    Nombre de tables de présentation (1 par salon)
                  </InputLabel><br />


                  <input type="text" className="inputForm familyref" placeholder="" name="table_number"
                    value={this.state.table_number} onChange={this.handleChange} onFocus={() => {
                      this.setState({
                        send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                        text_changed: false, btn_disabled: false
                      });
                      this.props.messageLogin();
                    }} />
                  {this.state.send_valid && this.state.table_number === "" &&
                    <span className="text-error">*Le Nombre de tables est obligatoire</span>
                  }
                </FormControl>
                <br />
                <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: pdgBottom }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
                    Nombre de corps (1 corps par salon minimum)
                  </InputLabel><br />
                </FormControl>
                <div className="reponsive-container">
                  <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: pdgBottom }}>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native"
                      value={this.state.positive_temp} name="positive_temp" id="labelForm2" >
                      Température positive
                    </InputLabel><br />
                    <input type="text" className="inputForm familyref" placeholder="" name="positive_temp"
                      value={this.state.positive_temp} onChange={this.handleChange} onFocus={() => {
                        this.setState({
                          send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                          text_changed: false, btn_disabled: false
                        });
                        this.props.messageLogin();
                      }} />
                    {this.state.send_valid && this.state.positive_temp === "" &&
                      <span className="text-error">*Température positive est obligatoire</span>
                    }
                  </FormControl>
                  <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: pdgBottom }}>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native"
                      value={this.state.negative_temp} name="negative_temp" id="labelForm2" >
                      Température négative
                    </InputLabel><br />
                    <input type="text" className="inputForm familyref" placeholder="" name="negative_temp"
                      value={this.state.negative_temp} onChange={this.handleChange} onFocus={() => {
                        this.setState({
                          send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                          text_changed: false, btn_disabled: false
                        });
                        this.props.messageLogin();
                      }} />
                    {this.state.send_valid && this.state.negative_temp === "" &&
                      <span className="text-error">*Température négative est obligatoire</span>
                    }
                  </FormControl>
                </div>

                <FormControl fullWidth style={{ paddingBottom: pdgBottom }}>
                  <FormLabel variant="standard" id="labelForm2" >Local technique</FormLabel><br />

                  <RadioGroup
                    onChange={(e) => {
                      this.setState({ dimensions_soins: "", dimensions_froide: "", dimensions: "" });
                      this.handleChange(e)
                    }}
                    aria-label="type"
                    name="local_type"
                    value={this.state.local_type}
                    onFocus={() => {
                      this.setState({
                        send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                        text_changed: false, btn_disabled: false
                      });
                      this.props.messageLogin();
                    }}
                  >
                    <FormControlLabel value="local_1" control={<Radio />} label="Local de soins et chambre froide dans la même pièce" />
                    <FormControlLabel value="local_2" control={<Radio />} label="Local de soins et chambre froide dans 2 pièces séparées" />
                  </RadioGroup>
                </FormControl>
                <br />

                {this.state.local_type === "local_2" ?
                  (<>
                    <FormControl fullWidth style={{ paddingBottom: pdgBottom }}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native"
                        value={this.state.dimensions_soins} name="dimensions_soins" id="labelForm2">
                        Dimension de la pièce de soins
                      </InputLabel>
                      {dimensions_infos}


                      <TextareaAutosize minRows={3} className="textareaForm" placeholder="" name="dimensions_soins"
                        value={this.state.dimensions_soins} onChange={this.handleChange} onFocus={() => {
                          this.setState({
                            send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                            text_changed: false, btn_disabled: false
                          });
                          this.props.messageLogin();
                        }} />
                      {this.state.send_valid && this.state.dimensions_soins === "" &&
                        <span className="text-error">*Dimension de la pièce de soins est obligatoire</span>
                      }
                    </FormControl>
                    <br />
                    <FormControl fullWidth style={{ paddingBottom: pdgBottom }}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native"
                        value={this.state.dimensions_froide} name="dimensions_froide" id="labelForm2">
                        Dimension de la pièce pour la chambre froide
                      </InputLabel>
                      {dimensions_infos}


                      <TextareaAutosize minRows={3} className="textareaForm" placeholder="" name="dimensions_froide"
                        value={this.state.dimensions_froide} onChange={this.handleChange} onFocus={() => {
                          this.setState({
                            send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                            text_changed: false, btn_disabled: false
                          });
                          this.props.messageLogin();
                        }} />
                      {this.state.send_valid && this.state.dimensions_froide === "" &&
                        <span className="text-error">*Dimension de la pièce pour la chambre froide est obligatoire</span>
                      }
                    </FormControl>
                    <br />
                  </>)
                  : (<>
                    <FormControl fullWidth style={{ paddingBottom: pdgBottom }}>
                      <InputLabel variant="standard" htmlFor="uncontrolled-native"
                        value={this.state.dimensions} name="dimensions" id="labelForm2">
                        Dimensions de la pièce (soins + chambre froide)

                      </InputLabel>
                      {dimensions_infos}

                      <TextareaAutosize minRows={3} className="textareaForm" placeholder="" name="dimensions"
                        value={this.state.dimensions} onChange={this.handleChange} onFocus={() => {
                          this.setState({
                            send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                            text_changed: false, btn_disabled: false
                          });
                          this.props.messageLogin();
                        }} />
                      {this.state.send_valid && this.state.dimensions === "" &&
                        <span className="text-error">*Dimensions de la pièce est obligatoire</span>
                      }
                    </FormControl>
                    <br />
                  </>)}

                <FormControl fullWidth style={{ paddingBottom: "0" }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
                    Pièces jointes <span className="facultatif">(optionnel)</span>
                  </InputLabel><br />
                  <Button style={{ marginTop: "3px" }}
                    variant="outlined"
                    component="label"
                    id="button-secondary"
                  >
                    Joindre un fichier
                    <input
                      onChange={this.handleChange}
                      id="file"
                      name="request_attachement"
                      type="file"
                      hidden
                      multiple={true} />
                  </Button>
                  <div id="attachContainer" className="attachedFilesContainer">
                    {Array.from(this.state.request_attachement).map((file, i) => {
                      return (<div key={i} className='attachedFile'>
                        <span className='attachedFileName'>{file.name}</span>
                        <Button onClick={() => this.removeFile({ i })}>
                          <svg key={i} className='MuiSvgIcon-root attachedFileIcon MuiSvgIcon-fontSizeMedium MuiChip-deleteIcon MuiChip-deleteIconMedium MuiChip-deleteIconColorDefault MuiChip-deleteIconOutlinedColorDefault css-i4bv87-MuiSvgIcon-root' focusable='false' viewBox='0 0 24 24' aria-hidden='true' data-testid='CancelIcon'><path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z'></path></svg>
                        </Button>
                      </div>)
                    }
                    )
                    }
                  </div>

                </FormControl>
                <br />
                <FormControl fullWidth style={{ paddingBottom: pdgBottom, marginTop: "20px" }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native"
                    value={this.state.questions} name="questions" id="labelForm2">
                    Questions du client restées sans réponse lors du rendez-vous <span className="facultatif">(optionnel)</span>

                  </InputLabel><br />

                  <TextareaAutosize minRows={3} className="textareaForm" placeholder="" name="questions"
                    value={this.state.questions} onChange={this.handleChange} onFocus={() => {
                      this.setState({
                        send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                        text_changed: false, btn_disabled: false
                      });
                      this.props.messageLogin();
                    }} />
                </FormControl>
                <br />

                <FormControl fullWidth style={{ paddingBottom: pdgBottom }}>
                  <FormLabel variant="standard" id="labelForm2" >Concurrence</FormLabel><br />

                  <RadioGroup
                    onChange={(e) => {
                      this.handleChange(e)
                    }}
                    aria-label="type"
                    name="concurrence"
                    value={this.state.concurrence}
                    onFocus={() => {
                      this.setState({
                        send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                        text_changed: false, btn_disabled: false
                      });
                      this.props.messageLogin();
                    }}
                  >
                    <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
                    <FormControlLabel value="Non" control={<Radio />} label="Non" />
                    <FormControlLabel value="Ne sais pas" control={<Radio />} label="Ne sais pas" />
                  </RadioGroup>
                </FormControl>
                <br />
              </>
            )}

            <FormControl fullWidth style={{ marginTop: "16px", lineHeight: "12px", paddingBottom: "32px" }}>
              <Button className="sendButton" variant="contained" onClick={this.handleSubmit} id="button-primary" disabled={valid_button}>
                {btn_text}
                {this.state.btn_disabled && text_changed && <img src={`${process.env.PUBLIC_URL}/style/reload.gif`} style={{ width: "100px" }} />}
              </Button>
            </FormControl>
          </Box>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    equipementRequest: (formDAta) => dispatch(equipementRequest(formDAta, ownProps)),
    messageLogin: () => dispatch(messageLogin()),
  };
};
const mapStateToProps = ({ eventGRC, usersReducer }) => {
  return {
    client: eventGRC.client,
    message_error: usersReducer.message_error,
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(EquipementForm);