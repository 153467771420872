import { ADD_EVENT, EVENTS_LIST, EVENT_EDIT, SHOW_MESSAGE, EVENTS_INITIAL, LOGOUT, EVENT_FILTER, ACTION_EVENT } from "./types";

import http from '../lib/axios'
export function addEvent(events, ownProps) {
  return (dispatch) => {
    return http.post('/events', events)
      .then((response) => {
        response.status !== 500 ?
          dispatch({
            type: ADD_EVENT,
            payload: {
              show_message: true,
            },

          }, ownProps.history.push({
            pathname:"/alertsuccess",
            state: { message: 'Votre événement a été ajouté!' }
          })) :
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              show_message: false,
            },
          })

      }).catch((e) => {
        dispatch({
          type: LOGOUT
        }, ownProps.history.push("/login"))
      });
  };
}

export function deleteEvent(events, ownProps) {

  return (dispatch) => {
    return http.delete('/events/' + events.idEvenement, { data: events })
      .then((response) => {
        response.status !== 500 ?
          dispatch({
            type: ACTION_EVENT.DELETE_EVENT,
            payload: {
              show_message: true,
            },

          }, ownProps.history.push({
            pathname:"/alertsuccess",
            state: { message: 'Votre événement a été supprimé!' }
          })) :
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              show_message: false,
            },
          })

      }).catch((e) => {
        dispatch({
          type: LOGOUT
        }, ownProps.history.push("/login"))
      });
  };
}

export function editEvent(events, ownProps) {
  return (dispatch) => {
    return http.patch('/events/' + events.idEvenement, events)
      .then((response) => {
        response.status !== 500 ?
          dispatch({
            type: ACTION_EVENT.EDIT_EVENT,
            payload: {
              show_message: true,
            },

          }, ownProps.history.push({
            pathname:"/alertsuccess",
            state: { message: 'Votre événement a été modifié!' }
          })) :
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              show_message: false,
            },
          })

      }).catch((e) => {
        dispatch({
          type: LOGOUT
        }, ownProps.history.push("/login"))
      });
  };
}

export function getEvents(userId, clientId, ownProps) {
  const user = JSON.parse(localStorage.getItem('user'));
  const filter_fields = {
    user_id: userId,
    client_id: clientId
  }

  return (dispatch) => {
    http.get(`/events`, {

      params: filter_fields
    }, filter_fields)
      .then(res => {
        const data = res.data
        dispatch({
          type: EVENTS_LIST,
          payload: {
            user_events: data.evenements,
          },
        }
        )
      }
      ).catch((e) => {
        dispatch({
          type: LOGOUT
        }, ownProps.history.push("/login"))
      });
  }

};

export function initializeEvents() {
  return (dispatch) => {
    dispatch({
      type: EVENTS_INITIAL
    })
  }
}


export function getEvent(event, ownProps) {
    return (dispatch) => {
      http.get(`/contacts/${event.listeClient}`)
        .then(res => {
          const data = res.data       
            dispatch({
            type: EVENT_EDIT,
            payload: {
              contacts: data.contacts,
              event_edit: event
            },
          }) 
          ownProps.history.push("/evenementshow") 
        });
    }
}
export function addFilterEvent(user, client) {
  const event_filter = { clientId: client, userId: user }
  return (dispatch) => {
    dispatch({
      type: EVENT_FILTER,
      payload: event_filter,
    })
  };
}