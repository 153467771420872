import * as React from 'react';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import TextareaAutosize from '@mui/material/TextareaAutosize';
import { requestTrello } from '../actions/requestAction';
import { Component } from 'react';
import { connect } from "react-redux";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

import CloseIcon from '@mui/icons-material/Close';
import { messageLogin } from "../actions/loginUser";
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


class ActionClientForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request_title: "",
      request_description: "",
      request_attachment: [],
      send_valid: false,
      open_alert: false,
      btn_text: "Envoyer la demande",
      btn_disabled: false,
      text_changed: false,
      client: null,

    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  removeFile(ind) {
    let attachement = Array.from(this.state.request_attachment);
    const input = document.getElementById("file");
    const dt = new DataTransfer()
    if (attachement.length == 1) {
      attachement = []
      input.files = dt.files
    }
    else {
      const attachs = []

      for (let i = 0; i < attachement.length; i++) {
        if (i !== ind.i) {
          const file = attachement[i]
          attachs.push(file)
          dt.items.add(file)
        }
        // here you exclude the file. thus removing it.
      }
      attachement = attachs

    }


    input.files = dt.files
    this.setState({ request_attachment: attachement })

  }
  componentDidMount() {
  
    if(this.props.client){
      const clientSelected=this.props.client;
      this.setState({ client: clientSelected })
    }
  }
  componentWillReceiveProps(nextProps) {
    
    this.setState({ client: nextProps.client })

  }

  showAttachedFiles() {
    const files = this.state.request_attachment;
    const FilesContainer = document.getElementById("attachContainer");
    if (FilesContainer) FilesContainer.innerHTML = "";

  }

  handleChange(e) {
    if (e.target.name !== 'request_attachment') {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
    else {
      let files = e.target.files;


      this.setState({
        [e.target.name]: files,
      })

    }

  }
  handleSubmit(e) {
    const { request_title, request_description, request_attachment, client, user } = this.state;
    const type = "actionClient"

    e.preventDefault();

    if (request_title !== "" && request_description !== "" && client !== null && ((user && user.trello !== "") || !user)) {
    
      const title = "[INTRANET] " + client.id + ' - ' + request_title + ' - ' + client.label.split("-")[0];
      let formData = new FormData();

      formData.append('description', request_description)
      formData.append('name', title)
      formData.append('type_request', type)
      formData.append('user', user ? user.trello : "")
      Array.from(request_attachment).forEach((item) => {
        formData.append('attachments[]', item);

      });
      this.props.requestTrello(formData);
      this.setState({
        btn_disabled: true,
        btn_text: "en cours...",
        text_changed: true
      });
    }
    this.setState({
      send_valid: true,
    });
  }
  render() {
    let clients = (this.props.store.user.params.clients.length > 0) ?
      this.props.store.user.params.clients.map(client => {
        return { label: client.nomClient + " - " + client.codeClient, id: client.codeClient }
      }) : [];
    let users = (this.props.store.user.params.users.length > 0) ?
      this.props.store.user.params.users.map(user => {
        return { label: user.nom,id: user.idUtilisateur, trello: user.idTrello, email: user.email }
      }) : [];
    const files = this.state.request_attachment ? this.state.request_attachment : [];
    let open_alert = this.state.open_alert
    let btn_text = this.state.btn_text
    let text_changed = this.state.text_changed
    if (this.props.message_error && this.state.send_valid) {
      open_alert = true;
      btn_text = "Envoyer la demande";
      text_changed = false;
    }
    const valid_button = this.state.btn_disabled

    const clientSelected = JSON.stringify(this.state.client)!="{}"?this.state.client:null;
  
    return (
      <div className="div-app">
        <div className="monumentForm" style={{ textAlign: "center" }}>
          <Box component="form" noValidate autoComplete="off" sx={{ minWidth: 120 }}>
            <h3>Demander une action client</h3>
            <Collapse in={open_alert}>
              <Alert severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({
                        open_alert: false, btn_text: "Envoyer la demande",
                        text_changed: false, btn_disabled: false, btn_text: "Envoyer la demande",
                        text_changed: false, btn_disabled: false
                      });
                      this.props.messageLogin();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                Une erreur s'est produite, veuillez réessayer ultérieurement.
              </Alert>
            </Collapse>
            <FormControl fullWidth style={{}} id={this.state.send_valid &&
              this.state.client === null ? "field_error" : ""}>
              <FormLabel variant="standard" id="labelForm2" >Client</FormLabel><br />
            
              <Autocomplete
                id="combo-box-demo"
                options={clients}
                value={clientSelected}
                name="clientId"
                className="inputForm select align-items-center"
                blurOnSelect
                renderInput={(params) => <TextField {...params} label="" variant="standard" />}
                onFocus={() => {
                  this.setState({
                    send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                    text_changed: false, btn_disabled: false
                  });
                  this.props.messageLogin();
                }}
                onChange={(event, newValue) => this.setState({ client: newValue })}
              />

              {this.state.send_valid && this.state.client === null &&
                <span className="text-error">*Client est obligatoire</span>
              } <br />
            </FormControl>
            <FormControl fullWidth style={{}} id={this.state.send_valid &&
              this.state.user?.trello === "" ? "field_error" : ""}>
              <FormLabel variant="standard" id="labelForm2" >Collaborateurs GPG</FormLabel><br />
              <Autocomplete
                id="combo-box-demo"
                options={users}
                className="inputForm select align-items-center"
                name="userId"
                blurOnSelect
                renderInput={(params) => <TextField {...params} label="" variant="standard" />}
                onFocus={() => {
                  this.setState({
                    send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                    text_changed: false, btn_disabled: false
                  });
                }}
                onChange={(event, newValue) => { this.setState({ user: newValue }) }}
              />
              {this.state.send_valid && this.state.user?.trello === "" &&
                <span className="text-error">
                ID trello inconnu pour l'utilisateur <b>{this.state.user.label}</b>, merci de contacter le support informatique.
                </span>
              } <br />
            </FormControl>
            <br />
            <FormControl fullWidth style={{ paddingBottom: "16px" }} id={this.state.send_valid &&
              this.state.request_title === "" ? "field_error" : ""}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2"  >
                Titre
              </InputLabel><br />


              <input type="text" className="inputForm title" placeholder="" name="request_title" onFocus={() => {
                this.setState({
                  send_valid: false, open_alert: false, btn_text: "Envoyer la demande",
                  text_changed: false, btn_disabled: false
                });
                this.props.messageLogin();
              }}
                onChange={this.handleChange} value={this.state.request_title} />
              {this.state.send_valid && this.state.request_title === "" &&
                <span className="text-error">*Le titre est obligatoire</span>
              }


            </FormControl>
            <FormControl fullWidth style={{ paddingBottom: "16px" }} id={this.state.send_valid &&
              this.state.request_description === "" ? "field_error" : ""}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2" >
                Description
              </InputLabel><br />


              <TextareaAutosize minRows={3} className="textareaForm" placeholder=""
                name="request_description" onChange={this.handleChange} onFocus={() => {
                  this.setState({ send_valid: false, open_alert: false });
                  this.props.messageLogin();
                }}
                value={this.state.request_description} />
              {this.state.send_valid && this.state.request_description === "" &&
                <span className="text-error">*La description est obligatoire</span>
              }


            </FormControl>
            <FormControl fullWidth style={{ paddingBottom: "0" }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
                Pièces jointes <span className="facultatif">(optionnel)</span>
              </InputLabel><br />
              <Button
                variant="outlined"
                component="label"
                id="button-secondary"
              >
                Joindre un Fichier
                <input
                  name="request_attachment"
                  id="file"
                  onChange={this.handleChange}
                  type="file"
                  hidden
                  multiple={true}
                />
              </Button>
              <div id="attachContainer" className="attachedFilesContainer">
                {Array.from(this.state.request_attachment).map((file, i) =>
                  <div className='attachedFile'><span className='attachedFileName'>
                    {file.name}</span><Button onClick={() => this.removeFile({ i })}>
                      <svg key={i} className='MuiSvgIcon-root attachedFileIcon MuiSvgIcon-fontSizeMedium MuiChip-deleteIcon MuiChip-deleteIconMedium MuiChip-deleteIconColorDefault MuiChip-deleteIconOutlinedColorDefault css-i4bv87-MuiSvgIcon-root' focusable='false' viewBox='0 0 24 24' aria-hidden='true' data-testid='CancelIcon'><path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z'></path></svg></Button></div>
                )
                }
              </div>

            </FormControl>
            <FormControl fullWidth style={{ marginTop: "16px" }}>
              <Button variant="contained" id="button-primary" onClick={this.handleSubmit} disabled={valid_button}>
                {btn_text}
                {this.state.btn_disabled && text_changed && <img src={`${process.env.PUBLIC_URL}/style/reload.gif`} style={{ width: "100px" }} />}
              </Button>
            </FormControl>



          </Box>
        </div>

      </div>
    );
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    requestTrello: (formData) => dispatch(requestTrello(formData, ownProps)),
    messageLogin: () => dispatch(messageLogin()),
  };
};
const mapStateToProps = ({ eventGRC, usersReducer }) => {
  return {
    contacts: usersReducer.contacts,
    message_error: usersReducer.message_error,
    client : eventGRC.client
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ActionClientForm);