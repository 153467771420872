export const LOGGED_IN = "LOGGED_IN";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const LOGGED_OUT = "LOGGED_OUT";
export const FAILED_LOGIN = "FAILED_LOGIN";
export const FAILED_SIGNUP = "FAILED_SIGNUP";
export const CONTACTS_LIST = "CONTACTS_LIST";
export const CONTACTS = "CONTACTS";
export const CLIENTS = "CLIENTS";
export const SUJET = "SUJET";
export const ADD_EVENT = "ADD_EVENT";
export const EVENTS_LIST = "EVENTS_LIST";
export const EVENT_EDIT= "EVENT_EDIT";
export const MESSAGE_LOGIN = "MESSAGE_LOGIN";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const ADD_MONUMENT = "ADD_MONUMENT";
export const ADD_EQUIPEMENT = "ADD_EQUIPEMENT";
export const SEND_REQUEST = "SEND_REQUEST";
export const EVENTS_INITIAL = "EVENTS_INITIAL";
export const CONTACTS_EVENT = "CONTACTS_EVENT";
export const EVENT_FILTER = "EVENT_FILTER";

export const ACTION_EVENT = {

    RELOAD_DATA:"RELOAD_DATA",
    EDIT_EVENT:"EDIT_EVENT",
    DELETE_EVENT:"DELETE_EVENT",
    CLIENT_SELECTED:"CLIENT_SELECTED",
    LOADING_TOGGLE_ACTION:"LOADING_TOGGLE_ACTION",
    GET_EXTRANET_URL:"GET_EXTRANET_URL"
}
