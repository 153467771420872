import { LOGIN, FAILED_LOGIN, MESSAGE_LOGIN, ACTION_EVENT } from "./types";
import http from '../lib/axios';

export function loginUser(user, ownProps) {
  localStorage.removeItem('clientSelected');
  return (dispatch) => {
     return http.post('/authentication', user)
        .then((response) => {
          response.status !== 500 && response.data.logged_in?
          response.status === 401 ? dispatch({
            type: FAILED_LOGIN,
              payload:{username: user.username,
                message_error: "server"
              }
          },ownProps.history.push("/login")):
          dispatch(
            {
              type: LOGIN,
              payload: {user: response.data.user, params: response.data.params, token: response.data.token, cacId:response.data.cacId, trello_url: response.data.trelloBoard},
            },
            ownProps.history.push("/evenements")
            //<Redirect to="/evenements" />
          ) :
          dispatch(
            {
              type: FAILED_LOGIN,
              payload:{username: user.username,
                message_error: "password"
              }
            },
            ownProps.history.push("/")
          )
        })
        .catch((e)=>{
          dispatch(
            {
              type: FAILED_LOGIN,
              payload:{username: user.username,
                message_error: "server"
              }
            }
          )
        })
  };
}

export function   getCurrentUser() {
  return JSON.parse(localStorage.getItem('user'));;
}

export function messageLogin() {
  return (dispatch) => {
    dispatch(
      {
        type: MESSAGE_LOGIN,
      },
    )
  };
}

export function getextranetUrl(id){
  return (dispatch) => {
    return http.get('/getextranetUrl') 
    .then((response) => {
      dispatch(
          {
            type: ACTION_EVENT.GET_EXTRANET_URL,
            payload: {extranet_url: response.data.extranetUrl?.url},
          }
        ); 
        let url=response.data.extranetUrl?.url
        if(id && url)
          url+="&id="+id
        window.open(url, '_blank');
    })

  }
}