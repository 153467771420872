import logo from './logo.svg';
import './App.css';
import * as React from 'react';
import MenuIntranet from './components/MenuIntranet';
import Routes from './components/Routes';
import  useMediaQuery  from 'react-responsive';

import { BrowserRouter as Router} from "react-router-dom";

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)'
  })
  return (
    <div className="App">
      <Router>
        <MenuIntranet />    
        <Routes isDesktopDevice={isDesktopOrLaptop} />
      </Router>
    </div>
  );
}

export default App;
