import React, { Component } from "react";
import { connect } from "react-redux";
import NavBar from "../components/NavBar";


class MenuIntranet extends Component {
  componentDidMount() { 
    
  }

  render() {
    return (
      <NavBar className="navBarHead" isLoggedIn={this.props.isLoggedIn} user={this.props.user}  />
    );
  }
}

const mapStateToProps = ({ usersReducer}) => {
  const { isLoggedIn, user } = usersReducer;
  return {
    isLoggedIn: isLoggedIn,
    user: user,
  };
};

export default connect(mapStateToProps)(MenuIntranet);