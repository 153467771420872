import { SEND_REQUEST, SHOW_MESSAGE} from "./types";
import axios from 'axios';
import http from "../lib/axios";
import httpForm from "../lib/axios/httpform";

export function requestTrello(requestForm, ownProps) {
    return (dispatch) => {
      return httpForm.post(`/trello`, requestForm)
      .then((response) => {
        response.status !== 500 ?
          response.status ==401 ?dispatch({
            },ownProps.history.push("/login")):
          dispatch({
                type: SEND_REQUEST,
                payload: {
                   show_message: true,
                },
                
              },ownProps.history.push({
                pathname:"/alertsuccess",
                state: { message: 'Votre demande a bien été envoyée' }
              })):
              dispatch({
                  type: SHOW_MESSAGE,
                  payload: {
                     show_message: false,
                     message_error: true,
                  },
                })
           
        })
        .catch((e)=>{
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
               show_message: false,
               message_error: true,
            },
          })

        })
    };
  }