import * as React  from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { loginUser, messageLogin } from "../actions/loginUser";
import { Component } from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

import CloseIcon from '@mui/icons-material/Close';


class Login extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      btn_text: "Se connecter",
      btn_disabled: false,
      open: false,
      message: false,
      click_btn: false,
      text_changed:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      btn_text: "Se connecter",
      btn_disabled: false,
    });
  }

  handleSubmit(e) {
    
    const user= {username: this.state.username, password: this.state.password}
    
    e.preventDefault();
    
    this.props.loginUser(user);
    
    this.setState({
      // username: "",
      // password: "",
      btn_disabled: true,
      btn_text: "en cours...",
      text_changed:true
    });
    

    
    
  }

  
  render(){
    let btn_text = this.state.btn_text
    let text_changed= this.state.text_changed
    let open = this.state.open
    let message = ""
    if(this.props.message_login){
      btn_text= "Se connecter";
      text_changed = false;
      open= true;
      if(this.props.message_error=="server"){
        message = "Une erreur s'est produite, veuillez réessayer ultérieurement."
      }
      else{
        message = "Le nom d'utilisateur ou le mot de passe est incorrect."
      }
    }
    
    
    
    const { username, password } = this.state;
    const messageLog = this.state.message;
    const valid_button = !this.state.username || !this.state.password || this.state.btn_disabled
    
    return (
      <div style={{width:"100%", height:"100%"}}>
        
        <div className="LoginPortail" >
        
          <Box
              component="form"
              sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
              style = {{ }}
              class ="formLogin"
          >
            <img src={`${process.env.PUBLIC_URL}/style/LogoGPG2.png`} style={{width:"100px",paddingBottom: "24px"}}/>
            <Collapse in={open}>
              <Alert severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={()=> {this.setState({open: false, message: false, btn_text:"Se Connecter", text_changed: false,btn_disabled:false});
                    this.props.messageLogin();}}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {message}
              </Alert>
            </Collapse>
            <FormGroup style={{lineHeight: "71px", width:"100%"}}>
                <div><TextField fullWidth  id="standard-basic" label="Identifiant" value={username}
                  variant="standard" name="username" onChange={this.handleChange}
                  onFocus={()=> {this.setState({open: false, message: false,
                     btn_text:"Se Connecter",text_changed:false,btn_disabled:false});
                  this.props.messageLogin();}}/> </div>
                <div>
                  <TextField fullWidth id="standard-password-input"
                  
                label="Mot de passe"
                type="password"
                name="password"
                autoComplete="current-password"
                variant="standard"
                value={password}
                onChange={this.handleChange} 
                onFocus={()=> {this.setState({open: false, message: false, btn_text:"Se Connecter",text_changed:false,btn_disabled:false});
                this.props.messageLogin();}}/> </div>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Se souvenir de moi" />
                
                <Button variant="contained" id="connect" onClick={this.handleSubmit} 
                disabled={valid_button}>{btn_text}
                {this.state.btn_disabled && text_changed && <img src={`${process.env.PUBLIC_URL}/style/reload.gif`} style={{width:"100px"}}/>}
                </Button>            
            </FormGroup>            
          </Box>
        </div>
      </div>
      
    );
  } 
}
const mapStateToProps = ({ usersReducer }) => {
  return {
    usernameError: usersReducer.usernameError,
    message_login: usersReducer.message_login,
    message_error: usersReducer.message_error,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginUser: (formDAta) => dispatch(loginUser(formDAta, ownProps)),
    messageLogin: ()=>dispatch(messageLogin()),
  };
};

const customConnectComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default withRouter(customConnectComponent);