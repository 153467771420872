export function TrelloIcon(param) {
    return (
        <svg width={param.width} height={param.height} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M231 0H25C11.1929 0 0 11.1929 0 25V231C0 244.807 11.1929 256 25 256H231C244.807 256 256 244.807 256 231V25C256 11.1929 244.807 0 231 0Z" fill="url(#paint0_linear_1099_8014)" />
            <path d="M210.72 33.2798H156.64C150.012 33.2798 144.64 38.6524 144.64 45.2798V133.28C144.64 139.907 150.012 145.28 156.64 145.28H210.72C217.347 145.28 222.72 139.907 222.72 133.28V45.2798C222.72 38.6524 217.347 33.2798 210.72 33.2798Z" fill="white" />
            <path d="M99.3603 33.2798H45.2803C38.6529 33.2798 33.2803 38.6524 33.2803 45.2798V197.28C33.2803 203.907 38.6529 209.28 45.2803 209.28H99.3603C105.988 209.28 111.36 203.907 111.36 197.28V45.2798C111.36 38.6524 105.988 33.2798 99.3603 33.2798Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_1099_8014" x1="128" y1="0" x2="128" y2="256" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0091E6" />
                    <stop offset="1" stopColor="#0079BF" />
                </linearGradient>
            </defs>
        </svg>
    );
}