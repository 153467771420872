import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';


import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl';

import FormLabel from '@mui/material/FormLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import AutocompleteField from './autocompleteField';
import { Component } from 'react';
import { connect } from "react-redux";
import { contactsEvent } from "../actions/contacts";
import { editEvent, deleteEvent } from "../actions/events";
import NavBarShow from './NavbarShow';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { Button, Chip, Stack } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import { DeleteIcon } from './icon/deleteIcon';
import { SaveIcon } from './icon/saveIcon';
import { contactsList, clientsList, subjectList } from "../actions/eventGRC";
import ConfirmationModel from "./util/confirmationModel"
import { TrelloIcon } from './icon/trelloIcon';


class EventEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hour: '00',
            minute: '00',
            canal: '',
            send_valid: false,
            trello: '',
            comment: '',
            date: '',
            open: false,
            contacts:[],
            amount: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.child = React.createRef();
    }
    componentDidMount() {
        const event_date = this.props.eventEdit.dateEvenement.slice(6, 8) + '/'
            + this.props.eventEdit.dateEvenement.slice(4, 6) + '/'
            + this.props.eventEdit.dateEvenement.slice(0, 4)
        this.setState({
            trello: this.props.eventEdit.lienTrello,
            comment: this.props.eventEdit.commentaireEvenement,
            minute: this.props.eventEdit.dureeEvenement.slice(2, 4),
            hour: this.props.eventEdit.dureeEvenement.slice(0, 2),
            date: event_date,
            canal: this.props.eventEdit.idCanal,
            contacts:this.props.contactsEvent,
            amount: this.props.eventEdit.montant,
        })
        let elements = document.getElementsByClassName("navigationBar")
        if (elements && elements.length > 0) {
            elements[0].style.display = 'none';
        }
      
    }
 
    
    handleOpen = () => {
        this.setState({ open: true });
    }
    handleClose = () => {
        this.setState({ open: false });
    };

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
            send_valid: false,
            open_alert: false,
            btn_text: "Ajouter l'événement",
            text_changed: false,
            btn_disabled: false
        });
    };
    handleSubmit(e) {

        const clients_event = this.props.event.clients.map(client => client.id).join(';')
        const contacts_event = this.props.event.contacts.map(contact => contact.identifiant).join(';')
        const sujets_event = this.props.event.sujet.map(subject => subject.id).join(';')
        const { canal, hour, minute, trello, comment, amount } = this.state


        let heure = hour
        let minutes = minute

        if (canal !== "" && (hour !== "00" || minute !== "00") && this.props.event.clients.length > 0
            && this.props.event.contacts.length > 0 && this.props.event.sujet.length > 0) {
            if (parseInt(hour) < 10 && parseInt(hour) > 0) {
                heure = "0" + parseInt(hour)
            }
            if (parseInt(minute) < 10 && parseInt(minute) > 0) {
                minutes = "0" + parseInt(minute)
            }


            const duration = heure + '' + minutes
            const user = this.props.store.user;
            let date = this.state.date
            if (typeof date === 'string') {
                const dateString = this.state.date;
                const dateParts = dateString.split("/");
                date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            }
            const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
            const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)
            const dateEvent = date.getFullYear() + "" + month + "" + day

            const event_json = {
                idEvenement: this.props.eventEdit.idEvenement,
                canal_id: canal,
                topic_list: sujets_event,
                event_duration: duration,
                event_comment: comment,
                link_trello: trello,
                client_list: clients_event,
                contact_list: contacts_event,
                user_id: user.user.id,
                cac_id: this.props.eventEdit.idUser,
                event_date: dateEvent,
                montant: amount,
            };
            e.preventDefault();


            this.props.editEvent(event_json, user);
            this.setState({
                btn_disabled: true,
                btn_text: "en cours...",
                text_changed: true
            });


        }
        this.setState({
            send_valid: true,
        });
    }

    handleChangeClient(newValue) {
        this.props.clientsList([
            ...newValue,
        ]);

    }

    getContactsList =  () => {
       
        const contacts= this.props.contactsEvent
 
         return contacts? contacts.map(contact => {
            return {
                identifiant: contact.identifiant,
                title: contact.nomContact,
                id: contact.codeClient
            }
        }) : [] ;
    }

    getContactsSelected = () =>{
        return this.getContactsList().
        filter(contact => this.props.eventEdit.listeContact.split(';').includes(contact.identifiant))
    }
    
    render() {
        let btn_text = "Enregistrer";
        let text_changed = false;

        const d = this.state.date;
        let dateEvent = d.toString().includes("/") ? d.split('/')[2] + "-" + d.split('/')[1] + "-" + d.split('/')[0] : d;

        let clients = (this.props.store.user.params.clients.length > 0) ?
            this.props.store.user.params.clients.map(client => {
                return { title: client.nomClient + " - " + client.codeClient, id: client.codeClient, parent: client.parent }
            }) : [];

        

        let sujets = (this.props.store.user.params.sujets.length > 0) ?
            this.props.store.user.params.sujets.map(subject => {
                return { title: subject.nomSujet, id: subject.idSujet }
            }) : [];

        const canaux = this.props.store.user.params.canaux.map(canal => {
            return {
                label: canal.nomCanal.charAt(0).toUpperCase() + canal.nomCanal.slice(1).toLowerCase(),
                id: canal.idCanal
            }
        })
        let button = ""

        if (this.props.store.user.cacId == this.props.eventEdit.idUser) {
            button = <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: "32px" }}>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={this.handleOpen} color="error" fullWidth startIcon={<DeleteIcon color="#fff" width="24" height="24" />}>
                        Supprimer
                    </Button>
                    <ConfirmationModel open={this.state.open} handleClose={this.handleClose} user={this.props.store.user}
                        idEvenement={this.props.eventEdit.idEvenement} idUser={this.props.store.user.cacId} history={this.props.history} />
                    <Button variant="contained" id="button-primary" onClick={this.handleSubmit} fullWidth startIcon={<SaveIcon color="#fff" width="24" height="24" />}>
                        {btn_text}
                        {this.state.btn_disabled && text_changed && <img src={`${process.env.PUBLIC_URL}/style/reload.gif`} style={{ width: "100px" }} />}
                    </Button>
                </Stack>
            </FormControl>
        }
        let trelloBtn=""
        if(this.state.trello!=""){
            trelloBtn=<a href={this.state.trello} className='btn-hover'
            style={{position: "absolute",background:" #fff",padding: "7px",marginRight: "1px", borderLeft: "1px solid #ebebeb"}} target="_blank"><TrelloIcon width="24px" height="24px"/></a>
    
        }
       
        return (

            <>

                <div className="div-app">
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        style={{ float: "left" }}
                        id="basic-button"
                        className="backicon"

                        sx={{ mr: 2 }}
                        component={Link}
                        to="/evenements">
                        <ArrowBackIcon style={{ width: "40px", height: "40px" }} />
                    </IconButton>
                    <br />
                    <div style={{ width: "100%", position: "fixed", top: 0 }}>
                        <NavBarShow isLoggedIn={this.props.isLoggedIn} user={this.props.user} />
                    </div>
                    <div className="monumentForm editevent" style={{ textAlign: "center" }}>
                        <Box component="form" noValidate autoComplete="off" sx={{ minWidth: 120 }}>
                            <h3 style={{ marginBottom: "0px" }}>Événement GRC</h3>
                            <div style={{ textAlign: "left", marginBottom: "16px" }}><span style={{ color: "#a5a5a5" }}> Créé par :</span> <b>{this.props.eventEdit.user}</b></div>
                            <FormControl fullWidth style={{ lineHeight: "25px", paddingBottom: "14px" }}>

                                <AutocompleteField clients={clients} clientEvent={this.props.event.clients} client_edit={this.props.eventEdit.listeClient} eventEdit_page={true} selectLabel="Clients" />

                                {
                                    this.state.send_valid && this.props.event.clients.length === 0 &&
                                    <span className="text-error">*Champs clients est obligatoire</span>
                                }
                            </FormControl>
                            <FormControl fullWidth style={{ lineHeight: "25px", paddingBottom: "14px" }}>

                                <AutocompleteField contacts={this.getContactsList()} contactEvent={this.props.event.contacts} contact_edit={this.props.eventEdit.listeContact} eventEdit_page={true} selectLabel="Contacts" />

                                {
                                    this.state.send_valid && this.props.event.contacts.length === 0 &&
                                    <span className="text-error">*Champs contacts est obligatoire</span>
                                }
                            </FormControl>
                            <FormControl fullWidth style={{ lineHeight: "25px", paddingBottom: "14px" }}>


                                <AutocompleteField sujets={sujets} subject_edit={this.props.eventEdit.listeSujet} eventEdit_page={true} selectLabel="Sujets"/>
                                {
                                    this.state.send_valid && this.props.event.sujet.length === 0 &&
                                    <span className="text-error">*Champs sujets est obligatoire</span>
                                }
                            </FormControl>
                            <FormControl fullWidth >
                                <FormLabel variant="standard" id="labelForm2" >Canal</FormLabel><br />

                                <Autocomplete
                                    id="combo-box-demo"
                                    options={canaux}
                                    defaultValue={canaux.filter(canal => canal.id == this.props.eventEdit.idCanal)[0]}
                                    name="canal"
                                    blurOnSelect
                                    renderInput={(params) => <TextField {...params} label="" />}

                                    onChange={(event, newValue) => this.setState({ canal: newValue.id })}
                                />
                                {this.state.send_valid && this.state.canal === "" &&
                                    <span className="text-error">*Canal est obligatoire</span>
                                }

                            </FormControl>
                            <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: "17px", display: "-webkit-inline-box" }}>
                                <div style={{ width: "50%", textAlign: "left", marginRight: "24px" }} className="eventDura">
                                    <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ paddingBottom: "18px", position: "relative" }}>
                                        Date
                                    </InputLabel>

                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}  >
                                        <DatePicker style={{ marginTop: "10px" }}
                                            label=""
                                            value={dateEvent}
                                            onChange={(newValue) => {
                                                this.setState({ date: newValue });
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>

                                <div style={{ width: "50%", textAlign: "left" }}>

                                    <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ paddingBottom: "0", position: "relative" }}>
                                        Durée
                                    </InputLabel>
                                    <div className="editDuration" style={{ textAlign: "initial" }}>
                                        <input type="text" className="inputForm" placeholder="00" name="hour"
                                            onChange={this.handleChange}
                                            value={this.state.hour}
                                            style={{ width: "50px", textAlign: "center" }} />
                                        <span className="durationHourLabel" style={{ fontWeight: 400, fontSize: 16 }}> h </span>
                                        <input type="text" className="inputForm" placeholder="00" name="minute"
                                            value={this.state.minute} onChange={this.handleChange}
                                            style={{ width: "50px", textAlign: "center" }} />
                                        <span className="durationMinLabel" style={{ fontWeight: 400, fontSize: 16 }}> min</span>

                                    </div>
                                    {this.state.send_valid && this.state.minute === "00" && this.state.hour === "00" &&
                                        <span className="text-error">*La durée est obligatoire</span>
                                    }
                                </div>



                            </FormControl>
                            <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: "12px" }}>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
                                    Montant <span className="facultatif">(optionnel)</span>
                                </InputLabel><br />
                                <input type="number" onChange={this.handleChange} className="inputForm" placeholder="" name="amount" value={this.state.amount} />
                              
                            </FormControl>
                            <FormControl fullWidth style={{  lineHeight: "24px", paddingBottom: "16px"  }}>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
                                    Commentaire
                                </InputLabel><br />
                                <TextareaAutosize minRows={3} onChange={this.handleChange} className="textareaForm trello" placeholder="" name="comment" value={this.state.comment} />
                            </FormControl>
                            <FormControl fullWidth style={{ lineHeight: "12px", paddingBottom: "32px" }}>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native" id="labelForm2">
                                    Lien Trello
                                </InputLabel><br />
                                <div style={{position:"relative",display:"flex",justifyContent:"end",alignItems:"center"}}>
                                    <input type="text" onChange={this.handleChange} className="inputForm trello" placeholder="" name="trello" value={this.state.trello} />
                                   { trelloBtn}
                                </div>
                                
                            </FormControl>
                            {button}

                        </Box>

                    </div>
                </div>
            </>
        );
    }

}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

        editEvent: (formDAta) => dispatch(editEvent(formDAta, ownProps)),
        deleteEvent: (formDAta) => dispatch(deleteEvent(formDAta, ownProps)),

        clientsList: (formDAta) => dispatch(clientsList(formDAta)),
        sujetList: (formDAta) => dispatch(subjectList(formDAta)),
        contactsList: (formDAta) => dispatch(contactsEvent(formDAta)),
    };
};
const mapStateToProps = ({ eventGRC, usersReducer }) => {

    const { isLoggedIn, user } = usersReducer;
    return {
        event: {
            clients: eventGRC.clients,
            contacts: eventGRC.contacts,
            sujet: eventGRC.sujet,
        },
        contacts: usersReducer.contacts,
        eventEdit: eventGRC.event_edit,
        contactsEvent:eventGRC.contactsEvent,
        isLoggedIn: isLoggedIn,
        user: user,
    };

};


export default connect(mapStateToProps, mapDispatchToProps)(EventEdit);